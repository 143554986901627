import { useLayoutEffect, useRef } from 'react';
import { useCallback, useState } from 'react';
import WebApp from '@twa-dev/sdk';

export const useCloudStorage = <T>(key: string, initialValue: T) => {
    const initialValueRef = useRef(initialValue);
    const keyRef = useRef(key);
    const valueRef = useRef(initialValue);

    const [storedValue, setStoredValue] = useState<T>(initialValue);

    useLayoutEffect(() => {
        try {
            WebApp.CloudStorage.getItem(keyRef.current, (error, result) => {
                if (error) {
                    console.log(error);
                    return;
                }
                const value = result ? (JSON.parse(result) as T) : initialValueRef.current;
                setStoredValue(value);
                valueRef.current = value;
            });
        } catch (error) {
            console.log(error);
        }
    }, []);

    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                const currentValue = valueRef.current;

                const valueToStore = value instanceof Function ? value(valueRef.current) : value;

                setStoredValue(valueToStore);
                valueRef.current = valueToStore;

                WebApp.CloudStorage.setItem(
                    keyRef.current,
                    JSON.stringify(valueToStore),
                    (error) => {
                        if (error) {
                            console.log(error);

                            setStoredValue(currentValue);
                            valueRef.current = currentValue;

                            return;
                        }
                    }
                );
            } catch (error) {
                console.log(error);
            }
        },
        [valueRef, setStoredValue]
    );

    return [storedValue, setValue] as const;
};
