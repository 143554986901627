import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import { BaseEntityStore } from 'common/constants/types.ts';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { Address } from '@ton/ton';
import { parseValue } from 'common/utils/parseValue.ts';
import { getSdk } from 'bigPumpSdk/sdk.store.ts';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { $user } from 'user/user.store.ts';
import { bugsnagClient } from 'bugsnag';
import { gtag } from 'gtag.ts';
import { OLD_MASTER_ADDRESS } from 'common/constants';
import { clearStats } from 'coin/coinStats/coinStats.store.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { clearTransactions } from 'coin/transactions/coinTransactions.store.ts';
import { clearKlines } from 'coin/chart/coinChart.store.ts';
import { tryCatch } from 'tryCatch';

export enum CoinStatus {
    Init = 'init', // created on backend
    Active = 'active', // deployed on chain
    Disabled = 'disable',
    LiquiditySent = 'liquidity-sent', // liquidity sent from the ton fun
}

export type Coin = {
    id: string;
    name: string;
    symbol: string;
    description: string;
    imageUrl: string;
    imageName: string;
    createdAt: string;
    status: CoinStatus; // ???
    user: {
        username: string;
    };
    authorAddress: string;
    updatedAt: string;
    address: string;
    decimals: number;
    tgChannel: null | string;
    tgChat: null | string;
    twitter: null | string;
    website: null | string;
    refId: string;
    chainCreatedAt: string;
    tonBalance: string;
    hotMeasure: string;
    hotMeasureUpdated: string;
    marketCap: string;
    totalSupply: string;
    bclSupply: string;
    bondingCurveProgress: string;
    availableToBuy: string;
    tonLiqCollected: string;
    tonSupply: string;
    currentPrice: string | null;
    bondingCurveProgressTon: string;
    liqCurrentUsdPrice?: string;
    liqUsdMarketCap?: string;
    masterAddress: string;
    priceChange1H: string;
    holderAmount: string;
    noteAmount: string;
    authorBalanceInTON: string;
};

interface CoinStore extends BaseEntityStore {
    coin?: Coin;
    coinBalance?: NumericString;
    isUnlocked: boolean | null;
}

export const $coin = map<CoinStore>({
    isFetched: false,
    isLoading: false,
    isUnlocked: null,
});

// actions

export const fetchCoinPriceById = action(
    $coin,
    'fetchCoinPriceById',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = store.get().coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinPriceById');
            setTimeout(() => fetchCoinPriceById(coinId), 1000);
            return;
        }

        if (bigPumpSdk) {
            try {
                const coinPrice = await bigPumpSdk.getCoinPrice(currentCoin.address);

                const decimal = currentCoin.masterAddress === OLD_MASTER_ADDRESS ? 18 : 9;
                store.setKey('coin', {
                    ...currentCoin,
                    currentPrice: parseValue(coinPrice, decimal),
                });
            } catch (e) {
                console.log('error while fetching coin price');
            }
        }
    }
);

export const fetchCoinBalanceById = action(
    $coin,
    'fetchCoinBalanceById',
    async (store, coinId: Coin['id']) => {
        const bigPumpSdk = getSdk();
        const currentCoin = store.get().coin;

        if (!currentCoin) {
            return;
        }

        if (!bigPumpSdk) {
            console.error('sdk not found on fetchCoinBalanceById');
            setTimeout(() => fetchCoinBalanceById(coinId), 1000);
            return;
        }

        if (bigPumpSdk) {
            try {
                const balance = await bigPumpSdk.getUserCoinBalance(currentCoin.address);

                store.setKey('coinBalance', parseValue(balance, 9));
            } catch {
                // store.setKey('coinBalance', 0);
            }
        }
    }
);

export const fetchCoinUnlocked = action($coin, 'fetchCoinUnlocked', async (store) => {
    const bigPumpSdk = getSdk();
    const currentCoin = store.get().coin;

    if (!currentCoin) {
        return;
    }

    if (!bigPumpSdk) {
        console.error('sdk not found on fetchCoinUnlocked');
        return;
    }

    if (bigPumpSdk) {
        try {
            const unlocked = await bigPumpSdk.checkIsLocked({
                coinAddress: currentCoin.address,
            });
            store.setKey('isUnlocked', unlocked ?? null);
        } catch {
            store.setKey('isUnlocked', false);
        }
    }
});

export const fetchCoinById = action($coin, 'fetchCoinById', async (store, coinId: Coin['id']) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.coinById + `/${coinId}`);

        if (response.ok) {
            const { currentPrice, ...data } = (await response.json()) as Coin;

            const currentCoin = store.get().coin;

            store.setKey('coin', {
                ...data,
                currentPrice: currentPrice ?? currentCoin?.currentPrice ?? null,
            });

            if (!store.get().isFetched) {
                fetchCoinBalanceById(coinId);
            }
            if (data.status === CoinStatus.LiquiditySent) {
                fetchCoinUnlocked();
            }
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.error('fetchKingOfHill error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const buyCoins = action(
    $coin,
    'buyCoins',
    async (store, amount: NumericString, coinAddress: string, slippagePercent?: bigint) => {
        const bigPumpSdk = getSdk();
        const coin = store.get().coin;

        if (!bigPumpSdk) {
            console.error('bigPumpSdk not found on buyCoins');
            return;
        }

        const user = $user.get().user;

        if (!user) {
            console.error('user not fetched on buyCoins');
            return;
        }

        try {
            gtag('meme_transaction', {
                wallet_address: tonConnectUI.account?.address ?? 'unknown',
                meme_name: coin?.name.trim() ?? 'unknown',
                action_type: 'buy',
                transaction_value: amount,
                fee: 'unknown',
            });
            await bigPumpSdk.buy({
                coinAddress,
                tons: amount as NumericString,
                userId: user.id,
                slippagePercent,
            });
            gtag('meme_transaction_success', {
                wallet_address: tonConnectUI.account?.address ?? 'unknown',
                meme_name: coin?.name.trim() ?? 'unknown',
                action_type: 'buy',
                transaction_value: amount,
                fee: 'unknown',
            });
        } catch (e) {
            console.log('error while buying coins', e);
        }
    }
);

export const sellCoins = action(
    $coin,
    'sellCoins',
    async (
        store,
        {
            amount,
            coinAddress,
            slippagePercent,
            tonsAmount,
        }: {
            amount: NumericString;
            coinAddress: string;
            slippagePercent?: bigint;
            tonsAmount: string;
        }
    ) => {
        const bigPumpSdk = getSdk();
        const coin = store.get().coin;

        if (!bigPumpSdk) {
            bugsnagClient.notify('bigpump sdk in not initialized on sellCoins');
            return;
        }

        const user = $user.get().user;

        if (!user) {
            bugsnagClient.notify('user not fetched on sellCoins');
            console.error('user not fetched on sellCoins');
            return;
        }

        if (!parseFloat(amount)) {
            bugsnagClient.notify('0 value for sell was received');
            throw new Error('No value for sell');
        }
        try {
            tryCatch(() => {
                gtag('meme_transaction', {
                    wallet_address: tonConnectUI.account?.address ?? 'unknown',
                    meme_name: coin?.name.trim() ?? 'unknown',
                    action_type: 'sell',
                    transaction_value: tonsAmount,
                    fee: 'unknown',
                });
            });
            await bigPumpSdk.sell({
                coinAddress,
                coins: amount as NumericString,
                userId: user.id,
                slippagePercent,
            });
            tryCatch(() => {
                gtag('meme_transaction_success', {
                    wallet_address: tonConnectUI.account?.address ?? 'unknown',
                    meme_name: coin?.name.trim() ?? 'unknown',
                    action_type: 'sell',
                    transaction_value: tonsAmount,
                    fee: 'unknown',
                });
            });
        } catch (e: any) {
            bugsnagClient.notify(e);
            console.log('error while selling coins', e);
        }
    }
);

export const unlockTokens = action($coin, 'unlockTokens', async (store) => {
    const coinAddress = store.get().coin?.address;
    const bigPumpSdk = getSdk();

    if (!bigPumpSdk) {
        console.error('bigPumpSdk not found on unlockTokens');
        return;
    }

    if (!coinAddress) {
        console.error('coin address not found on unlockTokens');
        return;
    }

    try {
        await bigPumpSdk.unlock({
            coinAddress,
        });

        fetchCoinUnlocked();
    } catch (e) {
        console.log('error while unlocking tokens', e);
    }
});

export const clearCoin = action($coin, 'clearCoin', (store) => {
    store.setKey('coin', undefined);
    store.setKey('coinBalance', undefined);
    store.setKey('isFetched', false);
    store.setKey('isUnlocked', null);
    clearStats();
    clearTransactions();
    clearKlines();
});

export const getCoinIdByAddress = action(
    $coin,
    'getCoinIdByAddress',
    async (store, address: string) => {
        try {
            const response = await authorizedFetch(
                routes.coinByAddress(Address.parse(address).toRawString())
            );

            if (response.ok) {
                const data = (await response.json()) as { id: string };

                console.log('getCoinIdByAddress', data.id);

                navigation.tokenTrade(data.id)();
                return;
            }
        } catch (e) {
            console.log('getCoinIdByAddress error', e);
        }
    }
);

// selectors

const selectCoinById = computed($coin, (store) => ({
    ...store,
    isFetching: store.isLoading && !store.isFetched,
}));

// hooks

export const useCoinById = () => useStore(selectCoinById);
