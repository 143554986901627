import WebApp from '@twa-dev/sdk';
import { releaseStage } from 'common/constants';
import posthog from 'posthog-js';
import { tryCatch } from 'tryCatch';

export const gtag = (type: string, data: Record<string, string | number>) => {
    if (releaseStage === 'development') return;
    try {
        tryCatch(() => {
            const project = 'bigpump';
            const env = releaseStage;

            const eventType = `${project}_${env}_${type}`;
            posthog?.capture(eventType, {
                ...data,
            });
        });

        if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
            window.dataLayer.push({
                event: type,
                user_id: WebApp.initDataUnsafe.user?.id ?? 'unknown',
                ...data,
            });
        }
    } catch (e) {
        console.error('gtag error', e);
    }
};
