import { ForwardedRef, forwardRef, HTMLProps, useEffect, useRef, useState } from 'react';

type InputProps = Omit<HTMLProps<HTMLTextAreaElement>, 'onChange' | 'value'> & {
    onChange?: (value: string) => void;
    className?: string;
    autoSize?: boolean;
    value?: string;
};

export const Textarea = forwardRef(
    (
        { onChange, className, onKeyUp, autoSize, ...props }: InputProps,
        ref: ForwardedRef<HTMLTextAreaElement>
    ) => {
        const textareaRef = useRef<HTMLTextAreaElement>(null);
        const [rows, setRows] = useState<number>(1);

        const classNames = `flex-1 border border-black bg-dark-blue p-2 text-xs placeholder:text-[#8FADE7] text-white ${className}`;

        useEffect(() => {
            if (textareaRef.current && autoSize) {
                if (!props.value?.length) {
                    textareaRef.current.style.height = 'inherit';
                    setRows(1);
                    return;
                }
                textareaRef.current.style.height = 'inherit';
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
        }, [props.value, autoSize]);

        return (
            <textarea
                rows={autoSize ? rows : undefined}
                {...props}
                onChange={(e) => onChange && onChange(e.currentTarget.value)}
                className={classNames}
                ref={(node) => {
                    textareaRef.current = node;

                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        ref.current = node;
                    }
                }}
            />
        );
    }
);
