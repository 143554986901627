export async function tryCatchAsync<T, E = unknown>(
    asyncFn: () => Promise<T>
): Promise<[T, null] | [null, E]> {
    try {
        const result = await asyncFn();
        return [result, null];
    } catch (error) {
        return [null, error as E];
    }
}

export function tryCatch<T, E = unknown>(fn: () => T): [T, null] | [null, E] {
    try {
        const result = fn();
        return [result, null];
    } catch (error) {
        return [null, error as E];
    }
}
