import { ReactNode } from 'react';

type Props = {
    isKeyboardOpen: boolean;
    children: ReactNode;
    fullHeight?: boolean;
};

export const ScreenLayout = ({ isKeyboardOpen, children, fullHeight }: Props) => {
    return (
        <div
            className={`flex flex-col items-center gap-3 ${isKeyboardOpen ? 'h-[50%] justify-end' : `${fullHeight ? 'h-full' : 'h-[80%] tall:h-[90%]'} justify-end`} transition`}
        >
            {children}
        </div>
    );
};
