import { useEffect, useState } from 'react';
import { NameScreen } from 'createToken/nameScreen.tsx';
import { ReactNode } from 'react';
import { navigation } from 'common/utils/routeUtils.ts';
import { TickerScreen } from 'createToken/tickerScreen.tsx';
import {
    clearStore,
    createCoin,
    fetchDeploymentFee,
    useCreateCoin,
} from 'createToken/createCoin.store.ts';
import classNames from 'classnames';
import { DescriptionScreen } from 'createToken/descriptionScreen.tsx';
import { ImageScreen } from 'createToken/imageScreen.tsx';
import { BuyScreen } from 'createToken/buyScreen.tsx';
import { ErrorBubble } from 'createToken/components/errorBubble.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonBalance } from 'user/user.store.ts';

type StoriesControlsProps = {
    count: number;
    activeStoryIndex: number;
};

export const StoriesControls = ({ count, activeStoryIndex }: StoriesControlsProps) => {
    return (
        <div
            className="grid items-center gap-1 w-36 h-1"
            style={{ gridTemplateColumns: `repeat(${count}, 1fr)` }}
        >
            {new Array(count).fill(1).map((_, index) => (
                <div
                    className={`h-full rounded-md bg-gradient-to-b ${activeStoryIndex < index ? 'from-white/40 to-white/20' : 'from-[#6CA7FF] to-light-blue'}`}
                />
            ))}
        </div>
    );
};

export enum CreateCoinScreen {
    Name,
    Ticker,
    Description,
    Image,
    Buy,
}

const createCoinScreens = [
    CreateCoinScreen.Name,
    CreateCoinScreen.Ticker,
    CreateCoinScreen.Description,
    CreateCoinScreen.Image,
    CreateCoinScreen.Buy,
];

export type CreateCoinScreenProps = {
    goNext: () => void;
    onClose?: () => void;
};

const screens: Record<CreateCoinScreen, (props: CreateCoinScreenProps) => ReactNode> = {
    [CreateCoinScreen.Name]: NameScreen,
    [CreateCoinScreen.Ticker]: TickerScreen,
    [CreateCoinScreen.Description]: DescriptionScreen,
    [CreateCoinScreen.Image]: ImageScreen,
    [CreateCoinScreen.Buy]: BuyScreen,
};

export const CreateCoinPage = () => {
    const { ticker, name, description, imageSrc, isCreating, isCreated, id } = useCreateCoin();

    useEffect(() => {
        fetchDeploymentFee();
    }, []);

    useAutoFetch(fetchTonBalance);

    const [activeScreen, setActiveScreen] = useState<CreateCoinScreen>(CreateCoinScreen.Name);
    const goNext = () => {
        const nextScreen = createCoinScreens[activeScreen + 1];
        if (typeof nextScreen !== 'undefined') {
            setActiveScreen(nextScreen);
        } else {
            createCoin();
        }
    };

    const goBack = () => {
        const prevScreen = createCoinScreens[activeScreen - 1];
        if (typeof prevScreen !== 'undefined') {
            setActiveScreen(prevScreen);
        }
    };

    const handleClose = () => {
        clearStore();
        if (isCreated) {
            navigation.tokenTrade(id)();
        } else {
            navigation.mainPage();
        }
    };

    const CurrentScreen = screens[activeScreen];

    const isLastScreen = activeScreen === CreateCoinScreen.Buy;

    return (
        <div
            className={classNames(
                `bg-gray h-dvh w-screen fixed top-0 left-0 overflow-y-auto overflow-x-hidden`,
                {
                    [`bg-[url('/create_coin_bg.webp')] bg-center bg-cover`]:
                        !imageSrc && !isLastScreen,
                    [`bg-gradient-to-b from-black to-transparent bg-center`]:
                        imageSrc && !isLastScreen,
                    [`bg-black bg-opacity-90 bg-[url('/coin_night_sky.gif')] bg-repeat bg-left-top`]:
                        isLastScreen,
                }
            )}
            style={imageSrc && !isLastScreen ? { backgroundImage: `url(${imageSrc})` } : {}}
        >
            <div
                className={classNames({
                    [`bg-gradient-to-b from-black to-transparent`]: !imageSrc && !isLastScreen,
                    [`min-h-[120px]`]: !isLastScreen,
                })}
            >
                <div className="flex items-center justify-between w-full h-12">
                    {activeScreen && !isCreating && !isCreated ? (
                        <button
                            className="w-11 h-11 flex items-center justify-center"
                            onClick={goBack}
                        >
                            <img src="/arrow_left.svg" alt="" className="w-3.5" />
                        </button>
                    ) : (
                        <div className="w-11 h-11" />
                    )}
                    <StoriesControls
                        count={createCoinScreens.length}
                        activeStoryIndex={activeScreen}
                    />
                    <button
                        className="w-11 h-11 flex items-center justify-center"
                        onClick={handleClose}
                    >
                        <img src="/cross.svg" alt="" className="w-3" />
                    </button>
                </div>
                <p
                    className={classNames('text-white text-center', {
                        [`hidden`]: activeScreen < 1 || isLastScreen,
                    })}
                >
                    {name}
                </p>
                <p
                    className={classNames('text-white text-center text-xs', {
                        [`hidden`]: activeScreen < 2 || isLastScreen,
                    })}
                >
                    {ticker}
                </p>
                <p
                    className={classNames(
                        'text-white/80 text-center text-xs italic mt-2.5 break-word w-[80%] mx-auto',
                        {
                            [`hidden`]: activeScreen < 3 || isLastScreen,
                        }
                    )}
                >
                    {description}
                </p>
            </div>
            <div
                className={`w-full ${isLastScreen ? 'h-[calc(100%-48px)]' : 'h-[calc(100%-120px)]'} px-5 pb-5`}
            >
                {/* @ts-ignore */}
                <CurrentScreen goNext={goNext} onClose={handleClose} />
            </div>
            <ErrorBubble />
        </div>
    );
};
