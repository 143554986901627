import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/react';
import { validationSchema } from 'createToken/createToken.page.tsx';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { Address } from '@ton/ton';
import { Coin } from 'coin/coin.store.ts';
import { getSdk } from 'bigPumpSdk/sdk.store.ts';
import { tgChannelToUrl, twitterToUrl, websiteToUrl } from 'createToken/utils.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { $user } from 'user/user.store.ts';
import { NumericString } from 'bclSdkOriginal/types.ts';
import { gtag } from 'gtag.ts';
import { bugsnagClient } from 'bugsnag';

type CreateCoinStore = {
    name: string;
    ticker: string;
    description: string;
    twitter: string;
    tgChannel: string;
    tgChat: string;
    website: string;
    image: File | null;
    imageSrc?: string;
    error?: string;
    amountToBuy: string;
    isCreating: boolean;
    isCreated: boolean;
    deploymentFee: number;
    id: string;
    boughtAmount: string;
};

export const $createCoin = map<CreateCoinStore>({
    name: '',
    ticker: '',
    description: '',
    twitter: '',
    tgChannel: '',
    tgChat: '',
    website: '',
    image: null,
    amountToBuy: '',
    isCreating: false,
    isCreated: false,
    deploymentFee: 0.3,
    id: '',
    boughtAmount: '',
});

// actions
export const setCoinName = action($createCoin, 'setCoinName', (store, value: string) => {
    store.setKey('name', value);
});

export const setCoinTicker = action($createCoin, 'setCoinTicker', (store, value: string) => {
    store.setKey('ticker', value.toUpperCase());
});

export const setCoinDescription = action(
    $createCoin,
    'setCoinDescription',
    (store, value: string) => {
        store.setKey('description', value);
    }
);

export const setCoinSocial = action(
    $createCoin,
    'setCoinSocial',
    (store, value: string, social: 'twitter' | 'tgChannel' | 'tgChat' | 'website') => {
        store.setKey(social, value);
    }
);

export const setCoinImage = action($createCoin, 'setCoinImage', (store, value: File) => {
    if (!value) {
        return;
    }
    store.setKey('image', value);

    try {
        const reader = new FileReader();
        reader.onload = (e) => {
            store.setKey('imageSrc', e.target?.result as string);
        };
        reader.readAsDataURL(value);
    } catch (e) {
        console.log('error during image reading', e);
    }
});

export const setAmountToBuy = action($createCoin, 'setAmountToBuy', (store, value: string) => {
    store.setKey('amountToBuy', value);
    fetchDeploymentFee();
});

export const setError = action($createCoin, 'setError', (store, error: string) => {
    store.setKey('error', error);
});

export const resetError = action($createCoin, 'resetError', (store) => {
    store.setKey('error', undefined);
});

export const createCoin = action($createCoin, 'createCoin', async (store) => {
    store.setKey('isCreating', true);
    try {
        const {
            image,
            name,
            ticker,
            description,
            tgChannel,
            tgChat,
            twitter,
            website,
            amountToBuy,
        } = store.get();
        const { file, ...metadata } = validationSchema.parse({
            file: image,
            name: name.trim(),
            symbol: ticker.trim(),
            description: description.trim(),
            tgChannel: tgChannel ? tgChannelToUrl(tgChannel) : undefined,
            tgChat: tgChat ? tgChannelToUrl(tgChat) : undefined,
            twitter: twitter ? twitterToUrl(twitter) : undefined,
            website: website ? websiteToUrl(website) : undefined,
        });
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
            'meta',
            JSON.stringify({
                ...metadata,
                pubKey: tonConnectUI.account?.publicKey,
            })
        );

        gtag('meme_created', {
            wallet_address: tonConnectUI.account?.address ?? 'unknown',
            meme_name: name.trim(),
        });

        const response = await authorizedFetch(routes.coinsCreateCoin, {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            gtag('meme_created_image_uploaded', {
                wallet_address: tonConnectUI.account?.address ?? 'unknown',
                meme_name: name.trim(),
            });
            const { coin } = (await response.json()) as { coin: Coin };
            const bigPumpSdk = getSdk();
            if (!bigPumpSdk) {
                throw new Error('SDK not initialized');
            }

            const user = $user.get().user;

            if (!user) {
                throw new Error('User not found');
            }

            const firstBuy = Boolean(amountToBuy)
                ? { userId: user.id, tons: amountToBuy as NumericString }
                : undefined;

            try {
                await bigPumpSdk.deployCoin(
                    {
                        coinId: Number(coin.id),
                        authorAddress: Address.parse(tonConnectUI.account!.address),
                        name: coin.name,
                        symbol: coin.symbol,
                        description: coin.description,
                        imageUrl: coin.imageUrl,
                    },
                    firstBuy
                );

                gtag('meme_created_success', {
                    wallet_address: tonConnectUI.account?.address ?? 'unknown',
                    meme_name: name.trim(),
                });

                const coins = await bigPumpSdk.getFirstBuyCoinsForTons(
                    amountToBuy as NumericString
                );

                store.setKey('isCreated', true);
                store.setKey('id', coin.id);
                store.setKey('boughtAmount', String(parseValue(coins, 9)));
            } catch (e: any) {
                bugsnagClient.notify(e);
                console.log('error while deployCoin', e);
                setError('Error while deployCoin');
            }
        } else {
            bugsnagClient.notify('createCoin error');
            const error = await response.json();
            setError(error.message);
        }
    } catch (e: any) {
        bugsnagClient.notify(e);
        console.log('createCoin error', e);
    } finally {
        store.setKey('isCreating', false);
    }
});

export const fetchDeploymentFee = action($createCoin, 'fetchDeploymentFee', async (store) => {
    try {
        const bigPumpSdk = getSdk();

        if (!bigPumpSdk) {
            console.log('skd is not initialised while fetchDeploymentFee');
            return;
        }

        const amountToBuy = store.get().amountToBuy;

        const deploymentFee = await bigPumpSdk.getDeploymentFee(amountToBuy as NumericString);

        store.setKey('deploymentFee', parseFloat(parseValue(deploymentFee, 9)));
    } catch (e) {
        console.log('error while fetchDeploymentFee', e);
    }
});

export const clearStore = action($createCoin, 'clearStore', (store) => {
    store.setKey('name', '');
    store.setKey('ticker', '');
    store.setKey('description', '');
    store.setKey('twitter', '');
    store.setKey('tgChannel', '');
    store.setKey('tgChat', '');
    store.setKey('website', '');
    store.setKey('image', null);
    store.setKey('imageSrc', undefined);
    store.setKey('amountToBuy', '');
    store.setKey('isCreating', false);
    store.setKey('isCreated', false);
    store.setKey('id', '');
});

// selectors
const selectCreateCoin = computed($createCoin, (store) => store);

// hooks
export const useCreateCoin = () => useStore(selectCreateCoin);
