import { ConnectedWallet, TonConnectUI } from '@tonconnect/ui';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import {
    setConnectionModalState,
    setTonConnectAppName,
    setTonConnectConnectionStatus,
} from 'tonConnect/tonConnect.store.ts';
import language from 'i18n';
import { gtag } from 'gtag.ts';
import { tonProofService } from 'tonkeeper/tonproof.service.ts';

const tonConnectManifestUrl =
    import.meta.env.VITE_APP_ENV === 'production'
        ? 'https://bigpump.app/manifest.json'
        : 'https://stage.bigpump.app/stage-manifest.json';

export const tonConnectUI = new TonConnectUI({
    manifestUrl: tonConnectManifestUrl,
    restoreConnection: true,
    buttonRootId: 'ton-connect',
    language: language.language,
});

export const onTonConnectStatusChange = async (wallet: ConnectedWallet | null) => {
    if (wallet) {
        gtag('wallet_connected', {
            wallet_address: wallet.account.address,
        });
        setTonConnectConnectionStatus(true);
        setConnectionModalState(false);
        setTonConnectAppName(wallet.appName || wallet.device.appName);
        await authorizedFetch(routes.tonConnectConnect, {
            method: 'POST',
            body: JSON.stringify({
                publicKey: wallet.account.publicKey,
                address: wallet.account.address,
                type: wallet.appName || wallet.device.appName,
            }),
            headers: {
                'Content-Type': 'application/json',
                ...(tonProofService.accessToken
                    ? {
                          Authorization: `Bearer ${tonProofService.accessToken}`,
                      }
                    : {}),
            },
        });
    } else {
        setTonConnectConnectionStatus(false);
    }
};
