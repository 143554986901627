import { FC, HTMLAttributes, useEffect, useRef } from 'react';
import { Input } from 'common/components/input.tsx';
import classNames from 'classnames';
import { InputError } from 'common/form/inputError.tsx';
import { useTranslation } from 'i18n';
import { Skeleton } from 'common/components/skeleton.tsx';

type Props = {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    currency: string;
    containerClassName?: string;
    balance?: string;
    isBalanceLoading?: boolean;
    validationError?: string;
} & Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'className'>;

export const CurrencyInput: FC<Props> = ({
    value,
    onChange,
    disabled,
    currency,
    containerClassName,
    balance,
    isBalanceLoading,
    validationError,
    ...inputProps
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const placeholderRef = useRef<HTMLParagraphElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!inputRef.current || !placeholderRef.current) return;
        const input = inputRef.current;
        const placeholder = placeholderRef.current;
        const width = placeholder.getBoundingClientRect().width;
        input.style.width = `${value ? width : 80}px`;
    }, [value]);

    return (
        <>
            <label
                className={classNames(
                    `flex items-center relative w-full bg-dark-blue p-3 border ${validationError ? 'border-red-error' : 'border-black'}`,
                    containerClassName
                )}
            >
                <Input
                    ref={inputRef}
                    onChange={onChange}
                    value={value}
                    type="number"
                    className="!text-md w-[70px] outline-none !border-none !p-0 !bg-transparent"
                    disabled={disabled}
                    {...inputProps}
                />
                <p
                    className="absolute text-md opacity-0 text-transparent -z-10"
                    ref={placeholderRef}
                >
                    {value || '0.00'}
                </p>
                <div className="flex flex-col items-end w-min">
                    <p className="text-blue-sky ">{currency}</p>
                    {balance && (
                        <p className="text-2xs text-blue-sky whitespace-nowrap flex items-center gap-1">
                            {t('token.balance')}:{' '}
                            {isBalanceLoading ? (
                                <Skeleton size="sm" height="13px" width="30px" />
                            ) : (
                                balance
                            )}
                        </p>
                    )}
                </div>
            </label>
            <InputError meta={{ error: validationError ?? '', touched: true }} />
        </>
    );
};
