import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/react';
import { useCallback, useEffect } from 'react';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { IS_TON_KEEPER } from 'common/constants';
import { tonProofService } from 'tonkeeper/tonproof.service.ts';

type TonConnectStore = {
    isReady: boolean;
    isConnected: boolean;
    isConnectionModalOpened: boolean;
    appName: string;
};

export const $tonConnect = map<TonConnectStore>({
    isReady: false,
    isConnected: false,
    isConnectionModalOpened: false,
    appName: '',
});

export const setTonConnectConnectionStatus = action(
    $tonConnect,
    'setTonConnectConnectionStatus',
    (store, status: boolean) => {
        store.setKey('isConnected', status);

        if (IS_TON_KEEPER && status && !tonProofService.accessToken) {
            setTimeout(() => {
                if (!tonProofService.accessToken && tonConnectUI.connected) {
                    tonConnectUI.disconnect();
                }
            }, 500);
        }
    }
);

export const setConnectionModalState = action(
    $tonConnect,
    'setConnectionModalState',
    (store, state: boolean) => {
        store.setKey('isConnectionModalOpened', state);
    }
);

export const setTonConnectAppName = action(
    $tonConnect,
    'setTonConnectAppName',
    (store, appName: string) => {
        store.setKey('appName', appName);
    }
);

const setIsReady = action($tonConnect, 'setIsReady', (store, isReady: boolean) => {
    store.setKey('isReady', isReady);
});

const selectTonConnectConnectionStatus = computed($tonConnect, (store) => store.isConnected);

const selectConnectionModalState = computed($tonConnect, (store) => store.isConnectionModalOpened);

const selectTonConnectAppName = computed($tonConnect, (store) => store.appName);

const selectIsTonConnectReady = computed($tonConnect, (store) => store.isReady);

export const useIsTonConnectReady = () => useStore(selectIsTonConnectReady);

export const useTonConnectConnectionStatus = () => useStore(selectTonConnectConnectionStatus);

export const useTonConnectAppName = () => useStore(selectTonConnectAppName);

export const useConnectionModal = () => {
    const isOpened = useStore(selectConnectionModalState);

    const open = useCallback(async () => {
        setConnectionModalState(true);

        if (tonConnectUI.connected) {
            await tonConnectUI.disconnect();
        }
    }, []);
    const close = useCallback(() => setConnectionModalState(false), []);

    return {
        isOpened,
        open,
        close,
    };
};

export function useRestoreTonConnect() {
    const isReady = useIsTonConnectReady();

    useEffect(() => {
        if (tonConnectUI) {
            // tonConnectUI.connector.restoreConnection();
            Promise.race([
                tonConnectUI.connectionRestored.then((value) =>
                    setTonConnectConnectionStatus(value)
                ),
                new Promise((resolve) => setTimeout(resolve, 2000)),
            ]).finally(() => setIsReady(true));
        }
    }, []);

    useEffect(() => {
        if (isReady && tonConnectUI) {
            // tonConnectUI.connector.restoreConnection();
        }
    }, [isReady]);
}
