import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/react';

type AppStore = {
    isVisible: boolean;
};

export const $app = map<AppStore>({
    isVisible: true,
});

// actions

export const setIsVisible = action($app, 'setIsVisible', (store, isVisible: boolean) => {
    store.setKey('isVisible', isVisible);
});

// selectors

export const selectIsAppVisible = computed($app, (store) => store.isVisible);

// hooks

export const useAppVisibleStatus = () => useStore(selectIsAppVisible);
