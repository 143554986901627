import { Stories, Story, StoryComponent } from 'common/components/stories.tsx';
import { FC, PropsWithChildren } from 'react';
import { Card } from 'common/components/card.tsx';
import { useTranslation } from 'i18n';
import { useCloudStorage } from 'common/hooks/useCloudStorage.ts';
import { useEffect, useMemo } from 'react';
import { Button } from 'common/components/button.tsx';
import { releaseStage } from 'common/constants';

const BackButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Button
            onClick={onClick}
            className="h-12 px-3 bg-[#CECECE] border border-l-white border-t-white border-r-[#686868] border-b-[#686868]"
        >
            {t('back').toUpperCase()}
        </Button>
    );
};

const StoryCard: FC<
    PropsWithChildren<{ title: string; headerColor: string; onClose: () => void }>
> = ({ children, title, headerColor, onClose }) => {
    return (
        <Card className="w-full z-50 !bg-[#CECECE] !shadow-[2px_2px_0px_0px_#FFFFFF_inset] !border-0 border-white flex flex-col gap-5 !p-0">
            <div className="shadow-[-2px_-2px_0px_0px_#686868_inset]">
                <div
                    className="flex items-center justify-between px-5 py-3 border-2 border-r-[#686868] border-b border-b-[#686868] border-l-white border-t-white"
                    style={{ backgroundColor: headerColor }}
                >
                    <p className="font-semibold text-base">{title}</p>
                    <button
                        className="shadow-[-1px_-1px_0px_0px_#686868_inset] bg-[#D9D9D9]"
                        onClick={onClose}
                    >
                        <img
                            src="/onboarding/close-icon.svg"
                            alt="close"
                            className="h-3 w-3 p-0.5 shadow-[1px_1px_0px_0px_#FFFFFF_inset]"
                        />
                    </button>
                </div>
                <div className="p-5 flex flex-col gap-5 tall:gap-3">{children}</div>
            </div>
        </Card>
    );
};

const StoryContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="bg-[url('/windows_xp.webp')] bg-cover h-screen w-screen relative flex flex-col items-center justify-center p-5">
            {children}
        </div>
    );
};

const RowText: FC<PropsWithChildren> = ({ children }) => {
    return <span className="text-xs font-semibold">{children}</span>;
};

const FirstStory: StoryComponent = ({ onPrev, onNext, onClose }) => {
    const { t } = useTranslation();
    return (
        <StoryContainer>
            <StoryCard title={t('onboarding.first.header')} headerColor="#A1B9FF" onClose={onClose}>
                <img src="/onboarding/story-1.gif" alt="" className="h-[5.25rem] w-40 mx-auto" />
                <p
                    className="text-xl font-bold italic text-[#2869FF] text-center"
                    dangerouslySetInnerHTML={{
                        __html: t('onboarding.first.title').toUpperCase(),
                    }}
                />
                <div className="border-t-2 border-l-2 border-black bg-white/80 p-5 flex flex-col">
                    <div className="flex gap-4 items-center border-b border-black/10 pb-3 mb-3">
                        <img src="/onboarding/heart-icon.svg" alt="" className="h-5 -mt-0.5" />
                        <RowText>{t('onboarding.first.rows.1')}</RowText>
                    </div>
                    <div className="flex gap-4 items-center border-b border-black/10 pb-3 mb-3">
                        <img src="/onboarding/trending-icon.svg" alt="" className="h-5 -mt-0.5" />
                        <RowText>{t('onboarding.first.rows.2')}</RowText>
                    </div>
                    <div className="flex gap-4 items-center">
                        <img src="/onboarding/bullseye-icon.svg" alt="" className="h-5 -mt-0.5 " />
                        <RowText>{t('onboarding.first.rows.3')}</RowText>
                    </div>
                </div>
                <Button
                    onClick={onNext}
                    className="h-12 font-semibold w-full bg-gradient-to-r from-[#A1B9FF] via-[#D6E0FF] to-[#A1B9FF] border border-l-white border-t-white border-r-[#686868] border-b-[#686868]"
                >
                    {t('onboarding.first.action').toUpperCase()}
                </Button>
            </StoryCard>
        </StoryContainer>
    );
};

const SecondStory: StoryComponent = ({ onPrev, onNext, onClose }) => {
    const { t } = useTranslation();
    return (
        <StoryContainer>
            <StoryCard
                title={t('onboarding.second.header')}
                headerColor="#F695FF"
                onClose={onClose}
            >
                <img src="/onboarding/story-2.gif" alt="" className="h-[5.25rem] w-32 mx-auto" />
                <p
                    className="text-xl font-bold italic text-[#B438BE] text-center"
                    dangerouslySetInnerHTML={{
                        __html: t('onboarding.second.title').toUpperCase(),
                    }}
                />
                <div className="border-t-2 border-l-2 border-black bg-white/80 p-5 flex flex-col">
                    <div className="flex gap-4 items-center border-b border-black/10 pb-3 mb-3">
                        <img src="/onboarding/heart-icon.svg" alt="" className="h-5 -mt-0.5" />
                        <RowText>{t('onboarding.second.rows.1')}</RowText>
                    </div>
                    <div className="flex gap-4 items-center border-b border-black/10 pb-3 mb-3">
                        <img src="/onboarding/dollar-icon.svg" alt="" className="h-5 -mt-0.5" />
                        <RowText>{t('onboarding.second.rows.2')}</RowText>
                    </div>
                    <div className="flex gap-4 items-center">
                        <img src="/onboarding/megaphone-icon.svg" alt="" className="h-5 -mt-0.5 " />
                        <RowText>{t('onboarding.second.rows.3')}</RowText>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <BackButton onClick={onPrev} />
                    <Button
                        onClick={onNext}
                        className="h-12 font-semibold w-full bg-gradient-to-r from-[#F695FF] via-[#FAC7FF] to-[#F695FF] border border-l-white border-t-white border-r-[#686868] border-b-[#686868]"
                    >
                        {t('onboarding.second.action').toUpperCase()}
                    </Button>
                </div>
            </StoryCard>
        </StoryContainer>
    );
};

const ThirdStory: StoryComponent = ({ onPrev, onNext, onClose }) => {
    const { t } = useTranslation();
    return (
        <StoryContainer>
            <StoryCard title={t('onboarding.third.header')} headerColor="#73DB7F" onClose={onClose}>
                <img src="/onboarding/story-3.gif" alt="" className="h-[5.25rem] w-28 mx-auto" />
                <p
                    className="text-xl font-bold italic text-[#33831A] text-center"
                    dangerouslySetInnerHTML={{
                        __html: t('onboarding.third.title').toUpperCase(),
                    }}
                />
                <div className="border-t-2 border-l-2 border-black bg-white/80 p-5 flex flex-col">
                    <div className="flex gap-4 items-center border-b border-black/10 pb-3 mb-3">
                        <span className="border text-sm aspect-square w-5 h-5 text-center flex items-center justify-center">
                            1
                        </span>
                        <RowText>{t('onboarding.third.rows.1')}</RowText>
                    </div>
                    <div className="flex gap-4 items-center border-b border-black/10 pb-3 mb-3">
                        <span className="border text-sm aspect-square w-5 h-5 text-center flex items-center justify-center">
                            2
                        </span>
                        <RowText>{t('onboarding.third.rows.2')}</RowText>
                    </div>
                    <div className="flex gap-4 items-center">
                        <span className="border text-sm aspect-square w-5 h-5 text-center flex items-center justify-center">
                            3
                        </span>
                        <RowText>{t('onboarding.third.rows.3')}</RowText>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <BackButton onClick={onPrev} />
                    <Button
                        onClick={onNext}
                        className="h-12 font-semibold w-full bg-gradient-to-r from-[#60D26D] via-[#93F49E] to-[#60D26D] border border-l-white border-t-white border-r-[#686868] border-b-[#686868]"
                    >
                        {t('onboarding.third.action').toUpperCase()}
                    </Button>
                </div>
            </StoryCard>
        </StoryContainer>
    );
};

export const Onboarding = () => {
    const [state, setState] = useCloudStorage('bigpump_onboarding_stories', false);

    const handleFinish = () => {
        setState(true);
    };

    useEffect(() => {
        if (releaseStage !== 'production') {
            setState(false);
        }
    }, []);

    const stories: Story[] = useMemo(
        () => [
            {
                content: FirstStory,
            },
            {
                content: SecondStory,
            },
            {
                content: ThirdStory,
            },
        ],
        []
    );

    if (!state) {
        return <Stories stories={stories} onFinish={handleFinish} />;
    }
    return null;
};
