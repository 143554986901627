import { z } from 'zod';

export const validationSchema = z.object({
    name: z.string().max(12),
    symbol: z.string().max(8, { message: `validation.max9` }),
    description: z.string().max(280, { message: `validation.max60` }),
    twitter: z.string().url().optional(),
    tgChat: z.string().url().optional(),
    tgChannel: z.string().url().optional(),
    website: z.string().url().optional(),
    tokenBuy: z
        .string()
        .optional()
        .refine((value) => !value || Number.isFinite(Number(value)), 'validation.invalidNumber'),

    file: z.any().refine((value) => {
        return value && value instanceof File && value.type.indexOf('image/') >= 0;
    }, 'validation.invalidImage'),
});

export type CoinCreateFormValues = z.infer<typeof validationSchema>;

// export const CreateTokenPage = () => {
//     useBackButton({
//         callback: navigation.mainPage,
//         from: '/create',
//     });
//
//     const onSubmit = async (values: CoinCreateFormValues) => {
//         createCoin(values);
//     };
//
//     const { t } = useTranslation();
//
//     const handleValidate = useMemo(() => createValidator(validationSchema), []);
//
//     return (
//         <main class={'bg-black px-5 pt-5 w-full bottom-padding'}>
//             <Form
//                 onSubmit={onSubmit}
//                 validate={handleValidate}
//                 render={({ handleSubmit }) => (
//                     <form onSubmit={handleSubmit} class={'bg-form-gradient p-4'}>
//                         <h1
//                             class={`
//             font-IBMPlex
//             font-bold
//             italic
//             text-base
//             text-white
//             pb-3
//             text-center
//             `}
//                         >
//                             {t('createToken.title')}
//                         </h1>
//                         <ImageInput label={`createToken.token.name`} name="file" />
//                         <TextInput
//                             label={`createToken.token.name`}
//                             name="name"
//                             placeholder="createToken.token.name"
//                         />
//                         <TextInput
//                             label={`createToken.ticker.name`}
//                             name="symbol"
//                             placeholder="createToken.ticker.name"
//                         />
//                         <TextInput
//                             label={`createToken.description`}
//                             type="textarea"
//                             name="description"
//                             placeholder="createToken.description.placeholder"
//                         />
//                         <TextInput
//                             label={`createToken.telegram`}
//                             placeholder="createToken.telegram.placeholder"
//                             name="tgChannel"
//                         />
//                         <TextInput
//                             label={`createToken.twitter`}
//                             placeholder="createToken.twitter.placeholder"
//                             name="twitter"
//                         />
//
//                         <TextInput
//                             label={`createToken.website`}
//                             placeholder="createToken.website.placeholder"
//                             name="website"
//                         />
//
//                         <TextInput
//                             label={`createToken.tokensAmount`}
//                             placeholder="createToken.tokensAmount.placeholder"
//                             name="tokenBuy"
//                         />
//                         <div
//                             class={
//                                 'flex flex-col bg-black fixed bottom-0 left-0 right-0 p-5 items-center'
//                             }
//                         >
//                             <p class={'text-light-gray mb-4'}>Cost of creation: ~0.5 TON</p>
//                             <BigColorButton type="submit">CREATE TOKEN</BigColorButton>
//                         </div>
//                     </form>
//                 )}
//             ></Form>
//         </main>
//     );
// };
