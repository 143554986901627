import { Input } from 'common/components/input.tsx';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Textarea } from 'common/components/textarea.tsx';

type CreateCoinInputProps = {
    value: string;
    placeholder: string;
    limit: number;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    type?: 'input' | 'textarea';
    error?: boolean;
};

const classNamesTextAreaRows = {
    1: '!text-[26px]',
    2: '!text-md',
    3: '!text-md',
    4: '!text-md',
};

export const CreateCoinInput = ({
    value,
    limit,
    onFocus,
    onBlur,
    onChange,
    placeholder,
    type = 'input',
    error,
}: CreateCoinInputProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [rows, setRows] = useState<1 | 2 | 3 | 4>(1);
    const handleFocus = () => {
        if (onFocus) {
            onFocus();
        }
    };
    const handleBlur = () => {
        if (onBlur) {
            onBlur();
        }
    };

    useEffect(() => {
        if (type === 'textarea' && textareaRef.current) {
            if (!value.length) {
                setRows(1);
                return;
            }
            const textareaWidth = textareaRef.current.getBoundingClientRect().width - 24 - 8;

            const measureSpan = document.createElement('span');
            measureSpan.className = `absolute opacity-0 ${classNamesTextAreaRows[1]} p-0 w-min`;
            // replace all spaces with underscores to measure width correctly
            measureSpan.innerText = value.replace(/ /g, '_');
            const appDiv = document.getElementById('app');

            appDiv?.appendChild(measureSpan);
            const measureSpanWidth = measureSpan.getBoundingClientRect().width;

            let calculatedRows = Math.ceil(measureSpanWidth / textareaWidth);
            if (calculatedRows > 2) {
                measureSpan.className = `absolute opacity-0 ${classNamesTextAreaRows[2]} p-0 w-min`;
                const measureSpanWidth = measureSpan.getBoundingClientRect().width;

                calculatedRows = Math.ceil(measureSpanWidth / textareaWidth);
            }

            measureSpan.className = `absolute opacity-0 ${classNamesTextAreaRows[rows]} p-0 w-min`;

            setRows(Math.min(calculatedRows, 4) as 1 | 2 | 3 | 4);
            measureSpan.remove();
        }
    }, [value]);

    const isInvalid = value.length > limit || error;

    return (
        <div
            className={`relative flex items-center bg-black rounded-xl w-full pr-2.5 gap-1 ${classNamesTextAreaRows[rows]}`}
        >
            {type === 'input' ? (
                <Input
                    className={`!bg-black ${isInvalid ? '!text-red' : ''} rounded-xl !text-[26px] leading-[48px] placeholder:text-white/40 placeholder:font-medium pl-6 w-full outline-none`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            ) : (
                <Textarea
                    className={`!bg-black ${isInvalid ? '!text-red' : ''} rounded-xl ${classNamesTextAreaRows[rows]} leading-[calc(48/26*100%)] placeholder:text-white/40 placeholder:font-medium pl-6 w-full outline-none resize-none`}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    rows={rows}
                    ref={textareaRef}
                />
            )}
            <span
                className={classNames(
                    `${value.length <= limit ? 'text-white/40' : 'text-red-error'} text-xs`,
                    {
                        [`opacity-0`]: value.length < limit - 2 && type === 'input',
                    }
                )}
            >
                {value.length}/{limit}
            </span>
        </div>
    );
};
