import { TonConnectUI } from '@tonconnect/ui';
import { Address, Cell, toNano } from '@ton/core';
import { BigPumpQueryId } from './query-id';
import { TonSdk } from './TonSdk';
import { NumericString } from './types';
import { Api, HttpClient } from 'tonapi-sdk-js';
import { BclSDK, Constants, packReferralConfig, tonConnectSender } from 'ton-bcl-sdk';
import {
    FEE_ADDRESS,
    MIN_BUY_AMOUNT,
    TON_API_KEY,
    TON_FUN_API_URL,
    TON_FUN_MASTER_ADDRESS,
} from 'common/constants';
import { retry } from 'common/utils/retry';

type DeployCoinInput = {
    authorAddress: Address;
    name: string;
    description: string;
    imageUrl: string;
    symbol: string;
    // coin.id from backend token creation.
    // Backend return string you can safely convert to number
    coinId: number;
};

export type TransactionStatus = 'done' | 'failed' | 'in_progress' | 'not_found';

class MyHttpProvider {
    get<T>(url: string): Promise<T> {
        return fetch(url).then((res) => res.json() as Promise<T>);
    }

    post<T>(url: string, body: unknown): Promise<T> {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((res) => res.json() as Promise<T>);
    }
}

type FirstBuyConfig = {
    userId: string;
    tons: NumericString;
};

export class BigPumpSdk {
    private readonly tonSdk: TonSdk;
    private readonly tonApi: Api<unknown>;
    private readonly bclSdk: BclSDK;

    constructor(private readonly tonConnectUI: TonConnectUI) {
        this.tonSdk = new TonSdk();
        this.tonApi = new Api(
            new HttpClient({
                baseUrl: 'https://tonapi.io',
                baseApiParams: {
                    headers: {
                        'Content-type': 'application/json',
                        ...(TON_API_KEY.length > 0
                            ? { Authorization: `Bearer ${TON_API_KEY}` }
                            : {}),
                    },
                },
            })
        );

        this.bclSdk = BclSDK.create({
            apiProvider: this.tonSdk.ton,
            clientOptions: {
                httpProvider: new MyHttpProvider(),
                endpoint: TON_FUN_API_URL,
            },
            masterAddress: Address.parse(TON_FUN_MASTER_ADDRESS),
        });
    }

    // please never change it without talking with backend/smartcontract team
    // it's used for queryId generation and very important for the system
    // to track the requests on our bakcned
    private serviceToken = 'bigpump';

    private defaultSlippage = 10n;
    private defaultSellSlippage = 30n;

    getServiceToken() {
        return this.serviceToken;
    }

    async deployCoin(config: DeployCoinInput, firstBuyConfig?: FirstBuyConfig) {
        const sender = tonConnectSender(this.tonConnectUI);

        const queryId = BigPumpQueryId.encode(this.getServiceToken(), config.coinId);

        const referralCell = packReferralConfig({ partner: FEE_ADDRESS });
        const infoForFirstBuy = await this.getInfoForFirstBuy(referralCell, firstBuyConfig);

        await this.bclSdk.deployCoin(
            sender,
            {
                authorAddress: config.authorAddress,
                name: config.name,
                description: config.description,
                imageUrl: config.imageUrl,
                symbol: config.symbol,
                referral: referralCell,
                queryId: queryId.toUint64(),
                extraMetadata: {},
            },
            { firstBuy: infoForFirstBuy }
        );

        return sender.getResult();
    }

    //     async getPool() {
    //         const pTON_V2_ADDRESS = Address.parse("EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S")

    //   const router = this.tonSdk.ton.open(
    //     DEX.v2_1.Router.create(
    //       "kQALh-JBBIKK7gr0o4AVf9JZnEsFndqO0qTCyT-D-yBsWk0v" // CPI Router v2.1.0
    //     )
    //   );

    //   return router.getPoolAddressByJettonMinters({token0: 'EQBNaXq0KcsfhhacKajy_us-GX_dm0MhW9rhODs6YS2Bv2ND', token1: pTON_V2_ADDRESS })
    //     }

    async getInfoForFirstBuy(referralCell: Cell, firstBuyConfig?: FirstBuyConfig) {
        if (!firstBuyConfig) {
            return undefined;
        }

        if (!firstBuyConfig.tons) {
            return undefined;
        }

        const sender = tonConnectSender(this.tonConnectUI);
        if (!sender.address) {
            throw new Error('please provide address');
        }

        const queryId = BigPumpQueryId.encode(
            this.getServiceToken(),
            Number(firstBuyConfig.userId)
        );

        const coins = await this.getFirstBuyCoinsForTons(firstBuyConfig.tons);

        return {
            buyerAddress: sender.address,
            referral: referralCell,
            queryId: queryId.toUint64(),
            minReceive: coins,
            tons: toNano(firstBuyConfig.tons),
        };
    }

    ton() {
        return this.tonSdk.ton;
    }

    async getTonPrice() {
        return await retry(() =>
            this.tonApi.rates.getRates({ tokens: ['ton'], currencies: ['usd'] })
        );
    }

    async getTonWalletBalance() {
        if (!this.tonConnectUI.account) {
            return 0n;
        }

        return this.tonSdk.getBalance(this.tonConnectUI.account.address);
    }

    async getFirstBuyCoinsForTons(tons: NumericString, withSlippage = true) {
        // NOTE: I think there is no slippage in firstBuy because it is **first** buy, i.e.
        // no one can impact the price during the operation
        // But we still need to verify it with tonfun, so for now preserve default behaviour
        // TODO: verify and either remove slippage or make it configurable
        const nanoTons = toNano(tons) - Constants.CoinBuyGas;
        const { coins } = await this.bclSdk.getFirstCoinsForTons(nanoTons);
        return withSlippage ? subtractSlippage(coins, this.defaultSlippage) : coins;
    }

    async estimateCoinsForTons(
        bclJettonMaster: Address,
        tons: NumericString,
        slippagePercent: bigint = this.defaultSlippage
    ) {
        // NOTE: I think we don't subtract gas here because it is added in buy method of ton-bcl-sdk.
        // But we still need to verify it with tonfun, so for now preserve default behaviour
        // TODO: verify and remove either gas subtraction or this comment
        const nanoTons = toNano(tons) - Constants.CoinBuyGas;
        const { coins } = await this.bclSdk.getCoinsForTons(bclJettonMaster, nanoTons);
        return {
            amount: coins,
            minAmount: subtractSlippage(coins, slippagePercent),
        };
    }

    async estimateTonsForCoins(
        bclJettonMaster: Address,
        coins: NumericString,
        slippagePercent: bigint = this.defaultSellSlippage
    ) {
        const nanoCoins = toNano(coins);
        const { tons } = await this.bclSdk.getTonsForCoins(bclJettonMaster, nanoCoins);
        return {
            amount: tons,
            minAmount: subtractSlippage(tons, slippagePercent),
        };
    }

    async buy(opts: {
        coinAddress: string;
        tons: NumericString;
        userId: string;
        slippagePercent?: bigint;
    }) {
        const nanoMin = toNano(MIN_BUY_AMOUNT);
        const nanoTons = toNano(opts.tons);
        if (nanoTons < nanoMin) {
            throw new Error(`Minimum amount is ${MIN_BUY_AMOUNT} TON`);
        }

        const parsedCoinAddress = Address.parse(opts.coinAddress);

        const coinsForTons = await this.estimateCoinsForTons(
            parsedCoinAddress,
            opts.tons,
            opts.slippagePercent
        );

        const queryId = BigPumpQueryId.encode(this.getServiceToken(), Number(opts.userId));

        const bclJetton = this.bclSdk.openCoin(parsedCoinAddress);
        const referralCell = packReferralConfig({ partner: FEE_ADDRESS });

        const sender = tonConnectSender(this.tonConnectUI);
        await bclJetton.sendBuy(sender, {
            tons: nanoTons,
            minReceive: coinsForTons.minAmount,
            referral: referralCell,
            queryId: queryId.toUint64(),
        });
    }

    async sell({
        coins,
        coinAddress,
        userId,
        slippagePercent,
    }: {
        coinAddress: string;
        coins: NumericString;
        userId: string;
        slippagePercent?: bigint;
    }) {
        const userAddress = this.tonConnectUI.account?.address;
        if (!userAddress) {
            console.log('user is not connected');
            return;
        }

        const parsedUserAddress = Address.parse(userAddress);
        const parsedCoinAddress = Address.parse(coinAddress);

        const queryId = BigPumpQueryId.encode(this.getServiceToken(), Number(userId));

        const tonsForCoins = await this.estimateTonsForCoins(
            parsedCoinAddress,
            coins,
            slippagePercent
        );

        const bclCoinWallet = await this.bclSdk.openUserCoinWallet(
            parsedCoinAddress,
            parsedUserAddress
        );

        const referralCell = packReferralConfig({ partner: FEE_ADDRESS });

        const sender = tonConnectSender(this.tonConnectUI);
        await bclCoinWallet.sendSellCoins(sender, {
            amount: toNano(coins),
            minReceive: tonsForCoins.minAmount,
            referral: referralCell,
            queryId: queryId.toUint64(),
        });
    }

    async unlock({ coinAddress }: { coinAddress: string }) {
        const userAddress = this.tonConnectUI.account?.address;
        if (!userAddress) {
            console.log('user is not connected');
            return;
        }

        const parsedUserAddress = Address.parse(userAddress);

        const parsedCoinAddress = Address.parse(coinAddress);

        const sender = tonConnectSender(this.tonConnectUI);
        const userCoinWallet = await this.bclSdk.openUserCoinWallet(
            parsedCoinAddress,
            parsedUserAddress
        );

        await userCoinWallet.sendUnlockWallet(sender, {});
    }

    async checkIsLocked({ coinAddress }: { coinAddress: string }) {
        const userAddress = this.tonConnectUI.account?.address;
        if (!userAddress) {
            console.log('user is not connected');
            return;
        }

        const parsedUserAddress = Address.parse(userAddress);
        const parsedCoinAddress = Address.parse(coinAddress);

        const userCoinWallet = await this.bclSdk.openUserCoinWallet(
            parsedCoinAddress,
            parsedUserAddress
        );

        return userCoinWallet.getTransfersEnabled();
    }

    async getUserCoinBalance(coinAddress: string) {
        return this.bclSdk.getUserCoinBalance(
            Address.parse(coinAddress),
            Address.parse(this.tonConnectUI.account!.address)
        );
    }

    async getDeploymentFee(firstBuyTons?: NumericString) {
        const masterData = await this.bclSdk.getMasterData();

        const val = masterData.deploymentFee + Constants.CoinDeploymentGas;

        if (firstBuyTons) {
            const nanoTons = toNano(firstBuyTons);
            return Constants.CoinBuyGas + nanoTons + val;
        }

        return val;
    }

    async getCoinPrice(coinAddress: string) {
        const coin = await this.bclSdk.openCoin(Address.parse(coinAddress));

        return coin.getCoinPrice();
    }

    fetchCoinDeployStatus = async (txHash: string): Promise<TransactionStatus> => {
        let res;
        try {
            res = await this.tonApi.events.getEvent(txHash);
        } catch (e) {
            return 'not_found';
        }

        if (res.in_progress) {
            return 'in_progress';
        }

        const masterCall = res.actions.find(
            (a) =>
                a.type === 'SmartContractExec' &&
                Address.parse(a.SmartContractExec?.contract.address!).equals(TON_FUN_MASTER_ADDRESS)
        );

        if (!masterCall || masterCall.status !== 'ok') {
            return 'failed';
        }

        const coinDeployment = res.actions.find((a) => a.type === 'ContractDeploy');

        if (!coinDeployment) {
            return 'failed';
        }

        const failedAction = res.actions.find((act) => act.status !== 'ok');
        if (failedAction) {
            return 'failed';
        }

        return 'done';
    };
}

export function getRandomUint32() {
    return Math.floor(Math.random() * 2 ** 32);
}

function subtractSlippage(value: bigint, slippage: bigint): bigint {
    // Ensure the slippage percentage is converted to a decimal by dividing by 100n
    const slippageDecimal = (slippage * 10n ** 10n) / 100n; // Use a high precision for decimal calculation

    // Calculate the slippage amount
    const slippageAmount = (value * slippageDecimal) / 10n ** 10n; // Apply the same precision

    // Adjust the value by the slippage amount
    const adjustedValue = value - slippageAmount;

    return adjustedValue;
}
