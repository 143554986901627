import { createElement, PropsWithChildren } from 'react';

type TextWithShadowProps = {
    children: PropsWithChildren['children'];
    className?: string;
    shadowColor?: string;
    shadowWidth?: number;
    tag?: 'span' | 'p';
};

export const TextWithShadow = ({
    children,
    className,
    shadowWidth = 1,
    shadowColor = '#000000',
    tag = 'span',
}: TextWithShadowProps) => {
    return createElement(tag, {
        className: `text-white font-bold ${className}`,
        style: {
            textShadow: `
            ${-shadowWidth}px ${-shadowWidth}px ${shadowWidth}px ${shadowColor},
            ${shadowWidth}px ${-shadowWidth}px ${shadowWidth}px ${shadowColor},
            ${-shadowWidth}px ${shadowWidth}px ${shadowWidth}px ${shadowColor},
            ${shadowWidth}px ${shadowWidth}px ${shadowWidth}px ${shadowColor},
            0 ${-shadowWidth}px ${shadowWidth}px ${shadowColor},
            0 ${shadowWidth}px ${shadowWidth}px ${shadowColor},
            ${-shadowWidth}px 0 ${shadowWidth}px ${shadowColor},
            ${shadowWidth}px 0 ${shadowWidth}px ${shadowColor}
            `,
        },
        children,
    });
};
