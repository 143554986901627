import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { Coin } from 'coin/coin.store.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';

type Stats = {
    buyCount: string;
    sellCount: string;
    transactionsCount: string;
    buyVolume: string;
    sellVolume: string;
    volume: string;
    buyerCount: string;
    sellerCount: string;
    tradersCount: string;
    priceChangePercentage: string;
};

export enum CoinStatsPeriod {
    '5m' = '5m',
    '15m' = '15m',
    '1h' = '1h',
    '24h' = '24h',
}

interface CoinStatsStore extends BaseEntityStore {
    stats: Record<CoinStatsPeriod, Stats | null>;
    selectedPeriod: CoinStatsPeriod;
}

export const $coinStats = map<CoinStatsStore>({
    isFetched: false,
    isLoading: false,
    stats: {
        '5m': null,
        '15m': null,
        '1h': null,
        '24h': null,
    },
    selectedPeriod: CoinStatsPeriod['24h'],
});

// actions

export const fetchCoinStats = action(
    $coinStats,
    'fetchCoinStats',
    async (store, coinId: Coin['id']) => {
        store.setKey('isLoading', true);

        try {
            const period = store.get().selectedPeriod;

            const response = await authorizedFetch(
                routes.coinByIdStats(coinId) + `?period=${period}`
            );

            if (response.ok) {
                const data = (await response.json()) as {
                    stats: Record<CoinStatsPeriod, Stats | null>;
                };

                store.setKey('stats', data.stats);
                store.setKey('isFetched', true);
            }
        } catch (e) {
            console.log(`error while fetching coinStats with ${coinId}`, e);
        } finally {
            store.setKey('isLoading', false);
        }
    }
);

export const setCoinStatsPeriod = action(
    $coinStats,
    'setCoinStatsPeriod',
    (store, period: CoinStatsPeriod) => {
        store.setKey('selectedPeriod', period);
    }
);

export const clearStats = action($coinStats, 'clearStats', (store) => {
    store.setKey('isFetched', false);
    store.setKey('isLoading', false);
    store.setKey('stats', {
        '5m': null,
        '15m': null,
        '1h': null,
        '24h': null,
    });
    store.setKey('selectedPeriod', CoinStatsPeriod['24h']);
});

// selectors
const selectCoinStats = computed($coinStats, (store) => store);

// hooks
export const useCoinStats = () => useStore(selectCoinStats);
