import { initTonConnect } from './tonConnect/init.ts';
import { MainLayout } from 'common/components/mainLayout.tsx';
import { MainPage } from 'main/main.page.tsx';
import { useEffect } from 'react';
import { TokenPage } from 'coin/token.page.tsx';
import { TokenPageMenu } from 'coin/tokenPageMenu.tsx';
import { TokenTradeTab } from 'coin/tabs/tokenTradeTab.tsx';
import { TokenAboutTab } from 'coin/tabs/tokenAboutTab.tsx';
import { onTonConnectStatusChange, tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchTonPrice } from 'main/main.store.ts';
import { NotificationRow } from 'main/notificationRow.tsx';
import { FrensPage } from 'frens/frens.page.tsx';
import { fetchMe, useUser } from 'user/user.store.ts';
import { WalletConnectModal } from 'tonConnect/walletConnectModal.tsx';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { CreateCoinPage } from 'createToken/createCoin.page.tsx';
import { initPocketFiBridge } from 'tonConnect/pocketfi.ts';
import { LoadingPage } from 'preloading/loading.page.tsx';
import { useIsTonConnectReady, useRestoreTonConnect } from 'tonConnect/tonConnect.store.ts';
import WebApp from '@twa-dev/sdk';
import { CabalArenaPage } from 'cabalArena/cabalArena.page.tsx';
import { initTonProofPayload, onTonProofConnect } from 'tonkeeper/hooks.ts';
import { IS_TON_KEEPER } from 'common/constants';
import { parseUtmMarks } from 'common/utils/parseUtmMarks.ts';
import isEmpty from 'lodash-es/isEmpty';
import { gtag } from 'gtag.ts';
import { LinkGeneratorPage } from 'linkGenerator/linkGenerator.page.tsx';
import { getCoinIdByAddress } from 'coin/coin.store.ts';
import { setIsVisible } from 'app.store.ts';
import { Onboarding } from 'onboarding/onboarding.tsx';
import { Route, Routes, useLocation } from 'react-router';
import PostHogPageView from 'posthog/PosthogPageView';

initTonConnect();
initPocketFiBridge();
initTonProofPayload();

export function App() {
    const { isFetched } = useUser();
    const isReady = useIsTonConnectReady();
    const { pathname } = useLocation();

    useAutoFetch(fetchMe);

    useRestoreTonConnect();

    useEffect(() => {
        const onVisibilityChange = () => {
            setIsVisible(document.visibilityState === 'visible');
        };

        document.addEventListener('visibilitychange', onVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);

    if (pathname === '/link-generator') {
        return <LinkGeneratorPage />;
    }

    if ((!isFetched && !IS_TON_KEEPER) || !isReady) {
        return <LoadingPage />;
    }

    return <AppRouter />;
}

function AppRouter() {
    useEffect(() => {
        tonConnectUI.onStatusChange(
            async (wallet) => {
                if (IS_TON_KEEPER) {
                    await onTonProofConnect(wallet);
                }
                await onTonConnectStatusChange(wallet);
            },
            (err) => {
                if (err) {
                    console.log(err);
                    tonConnectUI.disconnect();
                }
            }
        );

        const savedInitData = sessionStorage.getItem('initData');

        if (savedInitData !== WebApp.initData) {
            sessionStorage.setItem('initData', WebApp.initData);
            const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);

            if (startParams?.params) {
                const utmMarks = parseUtmMarks(startParams.params);
                if (!isEmpty(utmMarks)) {
                    gtag('utm_data', { ...utmMarks, ref_code: startParams.referralCode || 'none' });
                }
                if ('coinId' in startParams.params) {
                    navigation.tokenTrade(startParams.params.coinId)();
                } else if ('address' in startParams.params) {
                    getCoinIdByAddress(startParams.params.address);
                }
            }
        }
    }, []);

    useAutoFetch(fetchTonPrice, 60000);

    // useCabalArenaPage();

    return (
        <>
            <PostHogPageView />
            <Routes>
                <Route path="/" element={<MainLayout header={<NotificationRow />} />}>
                    <Route index element={<MainPage />} />
                </Route>

                <Route path="/create" element={<CreateCoinPage />} />

                <Route
                    path="/token/:tokenId/trade"
                    element={<MainLayout footer={<TokenPageMenu />} />}
                >
                    <Route
                        index
                        element={
                            <TokenPage>
                                <TokenTradeTab />
                            </TokenPage>
                        }
                    />
                </Route>

                <Route
                    path="/token/:tokenId/about"
                    element={<MainLayout footer={<TokenPageMenu />} />}
                >
                    <Route
                        index
                        element={
                            <TokenPage>
                                <TokenAboutTab />
                            </TokenPage>
                        }
                    />
                </Route>

                <Route path="/frens" element={<MainLayout color="#1A1A1A" />}>
                    <Route index element={<FrensPage />} />
                </Route>

                <Route path="/cabal-arena" element={<CabalArenaPage />} />
            </Routes>
            <WalletConnectModal />
            <Onboarding />
        </>
    );
}
