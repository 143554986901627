import { FC } from 'react';
import { useEffect, useState } from 'react';

type StoryComponentProps = { onNext: () => void; onPrev: () => void; onClose: () => void };

export type StoryComponent = FC<StoryComponentProps>;

export type Story = {
    content: StoryComponent;
};

type StoriesContainerProps = {
    stories: Story[];
    interval?: number;
    immediate?: boolean;
    onFinish: () => void;
};

export const Stories: FC<StoriesContainerProps> = ({ stories, immediate, onFinish }) => {
    const [open, setOpen] = useState(immediate);
    const [currentStory, setCurrentStory] = useState(0);

    const onAllStoriesEnd = () => {
        setOpen(false);
        onFinish();
    };

    const onNext = () => {
        if (currentStory < stories.length - 1) {
            setCurrentStory(currentStory + 1);
        } else {
            onAllStoriesEnd();
        }
    };

    const onPrev = () => {
        if (currentStory > 0) {
            setCurrentStory(currentStory - 1);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                setOpen(true);
            });
        }, 0);
    }, []);

    const StoryToShow = stories[currentStory].content;

    return (
        <div
            className={`fixed top-0 left-0 flex justify-center items-center w-screen h-screen ${open ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 z-50`}
        >
            <StoryToShow onNext={onNext} onPrev={onPrev} onClose={onFinish} />
            <div
                className="fixed top-0 left-0 w-full p-5 grid gap-1.5 items-center"
                style={{
                    gridTemplateColumns: `repeat(${stories.length}, 1fr)`,
                }}
            >
                {stories.map((_, index) => (
                    <div
                        key={index}
                        className={`h-1.5 rounded-full ${index === currentStory ? 'bg-white' : 'bg-white/30'}`}
                    />
                ))}
            </div>
        </div>
    );
};
