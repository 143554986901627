import { action, computed, map } from 'nanostores';
import { BaseEntityStore } from 'common/constants/types.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import { uniqBy } from 'lodash-es';

export type ReactionStates = {
    likeCount: number;
    dislikeCount: number;
    selfLike: boolean;
    selfDislike: boolean;
};

export type CommunityNote = {
    id: string;
    coinId: string;
    updatedAt: string;
    content: string;
    username: string;
} & ReactionStates;

interface CommunityNotesStore extends BaseEntityStore {
    notes: CommunityNote[];
    cursor?: string;
    sortType: 'likeCount' | 'id';
}

export const $communityNotesStore = map<CommunityNotesStore>({
    notes: [],
    isFetched: false,
    isLoading: false,
    sortType: 'likeCount',
});

export const fetchCommunityNotes = action(
    $communityNotesStore,
    'fetchCommunityNotes',
    async (store, coinId: string, sortType?: 'likeCount' | 'id') => {
        if (store.get().isLoading) return;
        store.setKey('isLoading', true);

        try {
            const sortTypeStored = store.get().sortType;
            const type = sortType ?? sortTypeStored;
            const currentCursor = store.get().cursor;

            const searchParams = new URLSearchParams({
                sortType: type,
            });

            if (currentCursor) {
                searchParams.append('cursor', currentCursor);
            }

            const response = await authorizedFetch(
                routes.communityNotes + '/' + coinId + '?' + searchParams.toString()
            );

            if (response.ok) {
                const { notes, nextCursor } = (await response.json()) as {
                    notes: CommunityNote[];
                    nextCursor: string | null;
                };
                store.setKey('notes', uniqBy([...store.get().notes, ...notes], 'id'));
                store.setKey('cursor', nextCursor ?? undefined);
                store.setKey('isFetched', true);
            }
        } catch (e) {
            console.error('fetchCommunityNotes error', e);
        } finally {
            store.setKey('isLoading', false);
        }
    }
);

export const addCommunityNote = action(
    $communityNotesStore,
    'addCommunityNote',
    async (store, { coinId, content }: { coinId: string; content: string }) => {
        try {
            const response = await authorizedFetch(routes.communityNotes, {
                method: 'POST',
                body: JSON.stringify({ coinId, content }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                fetchCommunityNotes(coinId);
            }
        } catch (e) {
            console.error('addCommunityNote error', e);
        }
    }
);

export const updateReaction = action(
    $communityNotesStore,
    'updateReaction',
    async (
        store,
        { noteId, reactionType }: { noteId: string; reactionType: 'like' | 'dislike' }
    ) => {
        try {
            const response = await authorizedFetch(routes.communityNotes + '/updateReaction', {
                method: 'POST',
                body: JSON.stringify({ communityNoteId: noteId, reactionType }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const notes = store.get().notes;
                store.setKey(
                    'notes',
                    notes.map((note) => {
                        if (note.id === noteId) {
                            const isLikeAdded = reactionType === 'like' && !note.selfLike;
                            const isDislikeAdded = reactionType === 'dislike' && !note.selfDislike;

                            return {
                                ...note,
                                selfLike: isLikeAdded,
                                selfDislike: isDislikeAdded,
                                likeCount: isLikeAdded
                                    ? note.likeCount + 1
                                    : !isLikeAdded && note.selfLike
                                      ? note.likeCount - 1
                                      : note.likeCount,
                                dislikeCount: isDislikeAdded
                                    ? note.dislikeCount + 1
                                    : !isDislikeAdded && note.selfDislike
                                      ? note.dislikeCount - 1
                                      : note.dislikeCount,
                            };
                        }
                        return note;
                    })
                );
            }
        } catch (e) {
            console.error('updateReaction error', e);
        }
    }
);

export const clearCommunityNotes = action($communityNotesStore, 'clearCommunityNotes', (store) => {
    store.setKey('notes', []);
    store.setKey('isFetched', false);
    store.setKey('cursor', undefined);
});

export const setSortType = action(
    $communityNotesStore,
    'setSortType',
    (store, sortType: 'likeCount' | 'id') => {
        store.setKey('sortType', sortType);
        clearCommunityNotes();
    }
);

// selectors

const selectCommunityNotes = computed($communityNotesStore, (store) => ({
    ...store,
    canFetchMore: Boolean(store.cursor),
    isFetching: store.isLoading && !store.isFetched,
}));

// hooks
export const useCommunityNotes = () => useStore(selectCommunityNotes);
