import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { Coin } from 'coin/coin.store.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';

export type THolder = {
    coinAddress: string;
    actorAddress: string;
    username: string | null;
    userId: string | null;
    sum: string;
    isAuthor: boolean;
};

interface HoldersStore extends BaseEntityStore {
    holders: THolder[];
}

export const $holders = map<HoldersStore>({
    isFetched: false,
    isLoading: false,
    holders: [],
});

// actions

export const fetchHolders = action($holders, 'fetchHolders', async (store, coinId: Coin['id']) => {
    store.setKey('isLoading', true);
    try {
        const response = await authorizedFetch(routes.coinByIdHolders(coinId));

        if (!response.ok) {
            console.log('Failed to fetch holders', response);
            return;
        }

        const data = await response.json();

        store.setKey('holders', data.holders);
        store.setKey('isFetched', true);
    } catch (e) {
        console.log('Failed to fetch holders', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

// selectors
const selectHolders = computed($holders, (store) => store);

export const useHolders = () => useStore(selectHolders);
