import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import { retry } from 'common/utils/retry.ts';

type TotalReferralsStat = {
    tonEarned: string;
    frens: number;
};

type ReferralsStat = TotalReferralsStat & {
    comission: number;
};

export type ReferralsStats = {
    total: TotalReferralsStat;
    level1: ReferralsStat;
    level2: ReferralsStat;
    level3: ReferralsStat;
};

interface FrensStore extends BaseEntityStore {
    stats?: ReferralsStats;
    frensCount?: number;
    waitlistUsers: number;
}

export const $frens = map<FrensStore>({
    isFetched: false,
    isLoading: false,
    waitlistUsers: 0,
});

// actions

export const fetchReferralsStat = action($frens, 'fetchReferralsStat', async (store) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.referralsStats);

        if (response.ok) {
            const stats = (await response.json()) as ReferralsStats;

            store.setKey('stats', stats);
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.log('fetchReferralsStat error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const sendReferralCode = action($frens, 'sendReferralCode', async (_, refCode: string) => {
    await retry(async () => {
        await authorizedFetch(routes.referralsRefer, {
            method: 'POST',
            body: JSON.stringify({
                refCode,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }, 5);
});

export const fetchDirectFrensCount = action($frens, 'fetchDirectFrensCount', async (store) => {
    try {
        const response = await authorizedFetch(routes.referralDirectFrensCount);

        if (response.ok) {
            const { count } = (await response.json()) as { count: number };

            store.setKey('frensCount', count);
        }
    } catch (e) {
        console.log('fetchDirectFrensCount error', e);
    }
});

export const fetchWaitlistUsers = action($frens, 'fetchWaitlistUsers', async (store) => {
    try {
        const response = await authorizedFetch(routes.whitelistTotal);

        if (response.ok) {
            const { total } = (await response.json()) as { total: number };

            store.setKey('waitlistUsers', total);
        }
    } catch (e) {
        console.log('fetchWaitlistUsers error', e);
    }
});

// selectors

const selectReferralsStats = computed($frens, (store) => store);

// hooks

export const useReferralsStats = () => useStore(selectReferralsStats);
