import dayjs from 'dayjs';

export const formatNewDuration = (str: string) => {
    const lang = dayjs().locale();

    if (lang === 'en') {
        return str
            .split(' ')
            .map((val, index) => {
                if (index) {
                    if (val === 'few') {
                        return '';
                    }
                    if (val.startsWith('m')) {
                        return val.slice(0, 2);
                    }
                    return val.slice(0, 1);
                }
                if (val !== 'a' && val !== 'an') {
                    return val;
                }
                return 1;
            })
            .join('');
    }

    return str
        .split(' ')
        .map((val, index, array) => {
            if (index) {
                if (val.startsWith('мес')) {
                    return val.slice(0, 3);
                }
                return val.slice(0, 1);
            }
            if (array.length > 1) {
                if (val.startsWith('нес')) {
                    return '1';
                }
                return val;
            }
            if (val.includes('мес')) {
                return `1${val.slice(0, 3)}`;
            }
            return `1${val.slice(0, 1)}`;
        })
        .join('');
};
