import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';
import { Coin } from 'coin/coin.store.ts';

interface KingOfHillStore extends BaseEntityStore {
    king?: Coin;
}

export const $kingOfHill = map<KingOfHillStore>({
    isFetched: false,
    isLoading: false,
});

// actions

export const fetchKingOfHill = action($kingOfHill, 'fetchKingOfHill', async (store) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.coinsKingOfHill);

        if (response.ok) {
            const data = (await response.json()) as { coin: Coin };

            store.setKey('king', data.coin);
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.error('fetchKingOfHill error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

// selectors

const kingOfHill = computed($kingOfHill, (store) => store);

// hooks

export const useKingOfHill = () => useStore(kingOfHill);
