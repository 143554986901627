import { FC } from 'react';

type Props = {
    size?: 'sm' | 'md' | 'lg';
    height?: number | string;
    width?: number | string;
};

const sizes = {
    sm: 'h-8',
    md: 'h-12',
    lg: 'h-16',
};

export const Skeleton: FC<Props> = ({ size = 'md', height, width }) => {
    return (
        <div
            className={`animate-pulse ${sizes[size]} bg-slate-700`}
            style={{ height, width }}
        ></div>
    );
};
