import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import classNames from 'classnames';
import { TranslateFn, useTranslation } from 'i18n';
import { useState } from 'react';
import { AboutTab } from 'cabalArena/aboutTab.tsx';

const mapTabNameWithType = (t: TranslateFn) => {
    return {
        about: t('cabal.tabs.about'),
        leaderboard: t('cabal.tabs.leaderboard'),
    };
};

const SORT_LIST_TABS = ['about', 'leaderboard'] as const;

export const CabalArenaPage = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('about');

    useBackButton({
        from: '/cabal-arena',
        callback: navigation.mainPage,
    });

    return (
        <div className="bg-blue bg-gradient-to-b from-white/40 to-transparent w-full pb-4">
            <div className="shadow sticky top-0 pt-4 bg-blue bg-gradient-to-b from-white/40 to-white/40">
                <button
                    className="absolute top-2 right-2 p-2.5 opacity-40"
                    onClick={navigation.mainPage}
                >
                    <img src="/cross.svg" alt="close" className="w-3 h-3" />
                </button>
                <TextWithShadow tag="p" className="text-center italic">
                    CABAL ARENA
                </TextWithShadow>
                <div className="flex items-center justify-between gap-1 overflow-x-auto w-full no-scrollbar py-3 px-5 pb-6">
                    {SORT_LIST_TABS.map((tab) => (
                        <button
                            className={classNames(
                                'border border-black bg-gradient-to-b text-xs px-1 py-[5px] whitespace-nowrap text-center flex justify-center items-center flex-1 leading-4 h-7 rounded-[10px] disabled:opacity-40',
                                {
                                    'from-[#FFB000] to-[#FFD16B] shadow-[0px_-3px_0px_0px_#305294]':
                                        selectedTab === tab,
                                    'from-[#4A6FB7] to-[#305294] text-white shadow-[0px_3px_0px_0px_#305294]':
                                        selectedTab !== tab,
                                }
                            )}
                            onClick={() => setSelectedTab(tab)}
                            disabled={tab === 'leaderboard'}
                        >
                            {mapTabNameWithType(t)[tab]}
                        </button>
                    ))}
                </div>
            </div>
            <div className="h-[calc(100%-88px)]">{selectedTab === 'about' && <AboutTab />}</div>
        </div>
    );
};
