import { setAmountToBuy, useCreateCoin } from 'createToken/createCoin.store.ts';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { CreateCoinScreenProps } from 'createToken/createCoin.page.tsx';
import { useKeyboardOpen } from 'common/hooks/useKeyboardOpen.ts';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { useTranslation } from 'i18n';
import { fetchTonBalance, useUser } from 'user/user.store.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { InputError } from 'common/form/inputError.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';

export const BuyScreen = ({ goNext, onClose }: CreateCoinScreenProps) => {
    const { t } = useTranslation();
    const {
        imageSrc,
        name,
        ticker,
        description,
        amountToBuy,
        isCreating,
        isCreated,
        error,
        deploymentFee,
        boughtAmount,
    } = useCreateCoin();
    const { tonBalance } = useUser();
    const numberTonBalance = parseFloat(parseValue(tonBalance ?? 0n, 9));

    const inputRef = useRef<HTMLInputElement>(null);

    const { isKeyboardOpen, handleFocus, handleBlur } = useKeyboardOpen();

    useEffect(() => {
        if (isKeyboardOpen) {
            inputRef.current?.scrollIntoView();
        } else {
            window.scrollTo({
                top: 0,
            });
        }
    }, [isKeyboardOpen]);

    useAutoFetch(fetchTonBalance);

    const onSubmit = () => {
        goNext();
    };

    return (
        <div
            className={`${isKeyboardOpen ? 'min-h-[calc(100%+45vh)] pb-[45vh]' : 'min-h-full'} flex flex-col items-center`}
        >
            {isCreated ? (
                <TextWithShadow className="relative text-3xl italic mb-5 mt-10 flex items-center justify-center">
                    {t('createToken.done').toUpperCase()}!
                    <p
                        className="absolute !bg-gradient-to-r from-[#B0FF5C] to-[#FFB000] bg-clip-text !text-transparent"
                        style={{
                            textShadow: 'none',
                        }}
                    >
                        {t('createToken.done').toUpperCase()}!
                    </p>
                </TextWithShadow>
            ) : (
                <img src="/green_arrow.gif" alt="" className="h-20 max-h-[7vh] aspect-square" />
            )}
            <div className="flex items-center w-full gap-2.5">
                <img
                    src="/coin_ticker.gif"
                    alt=""
                    className={classNames('h-8 z-10', {
                        [`opacity-0`]: isCreated,
                    })}
                />
                <div
                    className={classNames(
                        'flex flex-1 items-center justify-center flex-col bg-black rounded-xl px-2.5 py-5',
                        {
                            [`shadow-[0px_4px_120px_4px_#A600FF]`]: !isCreating && !isCreated,
                            [`shadow-[0px_4px_120px_4px_#0055FF]`]: isCreating,
                            [`shadow-[0px_4px_120px_4px_#00FF62]`]: isCreated,
                            [`!shadow-[0px_4px_120px_4px_#D12200]`]: error,
                        }
                    )}
                >
                    <img
                        src={imageSrc}
                        alt=""
                        className="h-[25vh] max-h-[11rem] aspect-square object-cover rounded-xl"
                    />
                    <p className="text-white leading-none mt-2.5">{name}</p>
                    <p className="text-white text-xs">{ticker}</p>
                    <p
                        className={`text-white/80 font-normal ${description.length > 150 ? 'text-xs' : 'text-sm'} italic mt-2.5 text-center`}
                    >
                        {description}
                    </p>
                </div>
                <img
                    src="/coin_ticker.gif"
                    alt=""
                    className={classNames('h-8 z-10', {
                        [`opacity-0`]: isCreated,
                    })}
                />
            </div>
            {!isCreating && !isCreated && (
                <>
                    <div className="mt-auto bg-black w-full p-2.5 rounded-xl">
                        <div className="flex items-center justify-center w-full p-3">
                            <span className="text-md font-normal text-white">
                                {t('createToken.preBuy.buy')}
                            </span>
                            <input
                                className="bg-black outline-none border-none px-2.5 text-md leading-7 text-white"
                                style={{
                                    width: `calc(1ch * ${amountToBuy.length + 1} + 1ch)`,
                                    minWidth: '3ch',
                                }}
                                placeholder="0"
                                value={amountToBuy}
                                onChange={(e) => {
                                    const value = e.currentTarget.value.replace(/[^0-9.]/g, '');
                                    setAmountToBuy(value);
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={inputRef}
                            />
                            <span className="text-md font-normal text-white">TON</span>
                        </div>
                        <p className="text-xs text-white/40 text-center mt-2.5">
                            {t('createToken.preBuy.info')}
                        </p>
                    </div>
                    <BigColorButton
                        variant="yellow"
                        className="py-1 mt-auto"
                        onClick={onSubmit}
                        disabled={deploymentFee > numberTonBalance}
                    >
                        <div className="flex items-center justify-between w-full gap-7">
                            <div className="flex items-center gap-1">
                                {t('createToken.createToken').toUpperCase()}
                                <img src="/arrow_right.gif" alt="" className="h-7" />
                            </div>
                            <div className="py-2 px-3 bg-black rounded-xl font-bold text-md">
                                <p
                                    className="bg-gradient-to-r from-[#B0FF5C] to-[#FFB000] bg-clip-text text-transparent !shadow-none pr-0.5"
                                    style={{
                                        textShadow: 'none',
                                    }}
                                >
                                    ~{deploymentFee} TON
                                </p>
                            </div>
                        </div>
                    </BigColorButton>
                    {deploymentFee > numberTonBalance && (
                        <InputError
                            meta={{
                                error: t('validation.notEnoughBalance'),
                                touched: true,
                            }}
                        />
                    )}
                </>
            )}
            {isCreating && (
                <div className="flex flex-col items-center mx-auto mt-auto mb-9">
                    <img src="/goose.gif" alt="" className="w-14 h-14" />
                    <p className="text-md text-white font-bold">{t('createToken.creatingToken')}</p>
                </div>
            )}
            {isCreated && (
                <div className="flex flex-col items-center mx-auto mt-auto mb-9 w-full">
                    {amountToBuy && (
                        <div className="flex items-center text-md text-white">
                            <span className="font-bold mr-2">{boughtAmount}</span>
                            <img src={imageSrc} alt="" className="w-6 h-6 mr-1" />
                            <span className="font-normal">{ticker}</span>
                        </div>
                    )}
                    <BigColorButton variant="yellow" className="py-2.5 mt-5" onClick={onClose}>
                        {t('createToken.close').toUpperCase()}
                    </BigColorButton>
                </div>
            )}
        </div>
    );
};
