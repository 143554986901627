import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/react';

type CoinStats = {
    coinAmount: string;
    earned: string;
};

interface CreateMemesStore extends BaseEntityStore {
    stats?: CoinStats;
}

export const $createMemesStore = map<CreateMemesStore>({
    isFetched: false,
    isLoading: false,
});

// actions

export const fetchCreateMemesStats = action(
    $createMemesStore,
    'fetchCreateMemesStats',
    async (store) => {
        store.setKey('isLoading', true);

        try {
            const response = await authorizedFetch(routes.ownerCoinStats);

            if (response.ok) {
                const stats = (await response.json()) as CoinStats;

                store.setKey('stats', stats);
                store.setKey('isFetched', true);
            }
        } catch (e) {
            console.log('fetchCreateMemesStats error', e);
        } finally {
            store.setKey('isLoading', false);
        }
    }
);

// selectors

const selectReferralsStats = computed($createMemesStore, (store) => store);

// hooks

export const useCreateMemesReferralsStats = () => useStore(selectReferralsStats);
