import WebApp from '@twa-dev/sdk';
import { parseStartParam } from 'common/utils/parseStartParam';
import { parseUtmMarks } from 'common/utils/parseUtmMarks';
import posthog from 'posthog-js';

try {
    posthog.init('phc_EnMnmA3RqP7ZsoxlotV689kEuy05gxl4qgqEhJgvsky', {
        api_host: 'https://posthog.pocketfi.org',
        autocapture: false,
        capture_pageview: false,
    });

    const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);
    const utmMarks = startParams.params ? parseUtmMarks(startParams.params) : undefined;

    posthog.unregister('ref_code');
    posthog.unregister('coin_id');
    posthog.unregister('address');
    posthog.unregister('$utm_source');
    posthog.unregister('$utm_medium');
    posthog.unregister('$utm_content');

    let userPropertiesToSetOnce: Record<string, any> = {};

    if (utmMarks) {
        userPropertiesToSetOnce.utm_source = utmMarks.utm_source;
        userPropertiesToSetOnce.utm_medium = utmMarks.utm_medium;
        userPropertiesToSetOnce.utm_content = utmMarks.utm_content;
        posthog.register_for_session({
            $entry_utm_source: utmMarks.utm_source,
            $entry_utm_medium: utmMarks.utm_medium,
            $entry_utm_content: utmMarks.utm_content,
        });
    }

    if (startParams?.referralCode) {
        userPropertiesToSetOnce.referral_code = startParams.referralCode;
        posthog.register_for_session({
            ref_code: startParams.referralCode,
        });
    }

    if (startParams?.params) {
        if ('coinId' in startParams.params) {
            userPropertiesToSetOnce.coin_id = startParams.params.coinId;
            posthog.register_for_session({
                coin_id: startParams.params.coinId,
            });
        }
        if ('address' in startParams.params) {
            userPropertiesToSetOnce.address = startParams.params.address;
            posthog.register_for_session({
                address: startParams.params.address,
            });
        }
    }

    posthog.identify(
        String(WebApp.initDataUnsafe.user?.id ?? 'unknown'),
        {
            is_premium: WebApp.initDataUnsafe.user?.is_premium || false,
            is_bot: WebApp.initDataUnsafe.user?.is_bot || false,
            language_code: WebApp.initDataUnsafe.user?.language_code || 'unknown',
        },
        userPropertiesToSetOnce
    );
} catch (error) {}
