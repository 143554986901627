import { useEffect } from 'react';
import { useLocation } from 'react-router';
import posthog from 'posthog-js';

export default function PostHogPageView() {
    let location = useLocation();

    // Track pageviews
    useEffect(() => {
        posthog?.capture('$pageview', {
            $current_url: window.location.href,
            $pathname: window.location.pathname,
            $host: window.location.host,
        });
    }, [location]);

    return null;
}
