import { PropsWithChildren } from 'react';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { clearCoin, fetchCoinById, useCoinById } from 'coin/coin.store.ts';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const TokenPage = ({ children }: PropsWithChildren) => {
    const { coin } = useCoinById();
    const { tokenId } = useParams();

    if (!tokenId) {
        throw new Error('Should be used on token page');
    }

    useAutoFetch(() => fetchCoinById(tokenId), 5000);

    useEffect(() => {
        if (coin && coin.id !== tokenId) {
            clearCoin();
        }
    }, [tokenId]);

    return <div className="flex flex-col gap-2.5 relative">{children}</div>;
};
