import { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'i18n';

type TokenCreatedByProps = {
    className?: string;
    children?: string;
    version?: 1 | 2;
};

/**
 * TokenCreatedBy component, show token creator
 * @param className - Wrapper class
 * @param children - Creator
 * @param isNew - new version in redesign
 * @constructor
 */
export const TokenCreatedBy: FC<TokenCreatedByProps> = ({ className, children, version }) => {
    const { t } = useTranslation();

    if (version === 2) {
        return (
            <span className={classNames('text-xs', className)}>
                <span className="opacity-65">{t('by')}</span>{' '}
                {children ? `@${children}` : t('anonymous').toLowerCase()}
            </span>
        );
    }

    return (
        <div className="flex items-center gap-1">
            <img src="/heart.gif" alt="heart" className="w-[14px] min-w-[14px]" />
            <span className={classNames('text-2xs text-white', className)}>
                {t('token-created-by')}: {children ? `@${children}` : t('anonymous').toLowerCase()}
            </span>
        </div>
    );
};
