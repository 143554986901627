import { Coin } from 'coin/coin.store.ts';
import { useTranslation } from 'i18n';
import { MouseEventHandler, useRef, useState } from 'react';
import { addCommunityNote } from 'coin/communityNotes/communityNotes.store.ts';
import { Button } from 'common/components/button.tsx';
import { Drawer } from 'common/components/drawer.tsx';
import { Textarea } from 'common/components/textarea.tsx';
import { setKeyboardState } from 'common/hooks/useKeyboardOpen.ts';
import { BigColorButton } from 'common/components/bigColorButton.tsx';

export const AddYourNote = ({ coinId }: { coinId: Coin['id'] }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef<HTMLTextAreaElement>(null);

    const onChange = (value: string) => {
        if (value.toLowerCase().includes('fuck')) {
            setError(t('community-notes.create.error.just-dont-it'));
        } else if (value.length > 640) {
            setError(t('community-notes.create.error.too-long'));
            return;
        } else {
            setError('');
        }
        setValue(value);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        await addCommunityNote({ coinId, content: value });
        setIsLoading(false);
        setValue('');
        setIsOpen(false);
    };

    const onOpen = () => {
        setKeyboardState(true);
        setIsOpen(true);
        if (ref.current) {
            ref.current.focus();
        }
    };

    const onClose: MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        setIsOpen(false);
    };

    return (
        <>
            <Button variant="blueGradient" className="border-black h-12" onClick={onOpen}>
                {t('community-notes.add-your-note').toUpperCase()}
            </Button>
            <Drawer open={isOpen} onClose={onClose} position="bottom">
                <div className="pb-8">
                    <div className="flex gap-2.5">
                        <img
                            src="/wtfck.png"
                            alt=""
                            className="w-6 min-w-[24px] h-6 aspect-square"
                        />
                        <Textarea
                            ref={ref}
                            className="!p-0 !pb-2 flex-1 text-white bg-transparent !font-normal !border-none !text-sm outline-none resize-none"
                            placeholder={t('community-notes.say-something')}
                            autoSize
                            value={value}
                            onChange={onChange}
                            onFocus={() => setKeyboardState(true)}
                            onBlur={() => setKeyboardState(false)}
                        />
                    </div>
                    {value.length > 0 && (
                        <BigColorButton
                            variant="yellow"
                            className="w-full h-12 mt-3"
                            onClick={onSubmit}
                            disabled={isLoading}
                        >
                            {t('community-notes.add-note').toUpperCase()}
                        </BigColorButton>
                    )}
                    {error && (
                        <>
                            <div className="">
                                <div className="bg-transparent opacity-0 p-2.5 text-xs text-white text-center mt-2">
                                    {error}
                                </div>
                            </div>
                            <div className="fixed bottom-0 w-screen left-0">
                                <div className="bg-light-red p-2.5 text-xs text-white text-center border-t-2 border-black">
                                    {error}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Drawer>
        </>
    );
};
