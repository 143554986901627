import { Card } from 'common/components/card.tsx';
import { Coin, useCoinById } from 'coin/coin.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { useTranslation } from 'i18n';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { GoldShareCoinButton } from 'coin/components/goldShareCoinButton.tsx';
import dayjs from 'dayjs';
import { LiquidityProgress } from 'coin/tabs/liquidityProgress.tsx';
import { getCoinMarketCap } from 'coin/utils.ts';
import { useTon } from 'main/main.store.ts';
import { CopyButton } from 'common/components/copyButton.tsx';
import WebApp from '@twa-dev/sdk';
import { openExternalLink } from 'common/utils/openExternalLink.ts';
import { Address } from '@ton/core';
import { CommunityNotesHeader } from 'coin/communityNotes/communityNotes.tsx';
import { useEffect, useState } from 'react';
import { RadioSlider } from 'common/components/radioSlider.tsx';
import { Holders } from 'coin/holders/holders.tsx';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { useParams } from 'react-router';

const widgets = {
    notes: CommunityNotesHeader,
    holders: Holders,
};

export const TokenAboutTab = () => {
    const { price } = useTon();
    const { coin } = useCoinById();
    const { t } = useTranslation();
    const { tokenId } = useParams();
    if (!tokenId) {
        throw new Error('Should be used on token page');
    }

    const [displayedWidget, setDisplayedWidget] = useState<'holders' | 'notes'>('holders');

    useBackButton({
        from: `/token/${tokenId}/about`,
        callback: navigation.tokenTrade(tokenId),
    });

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#community-notes') {
            document.getElementById('community-notes')?.scrollIntoView();
            setDisplayedWidget('notes');
        }
    }, []);

    const ActiveWidget = widgets[displayedWidget];

    if (!coin) {
        return <Skeleton size="lg" height={300} />;
    }

    return (
        <>
            <Card className="!bg-blue !p-0 relative overflow-hidden">
                <div
                    className={`absolute blur-3xl w-full h-full -translate-y-1/2`}
                    style={{
                        backgroundImage: `url(${coin.imageUrl})`,
                    }}
                />
                <div className="flex flex-col gap-3 items-center p-4 z-10">
                    <img
                        src={coin.imageUrl}
                        alt={coin.imageName}
                        className="w-44 max-h-[20vh] aspect-square object-contain z-10"
                    />
                    <div className="flex flex-col items-center w-full z-10">
                        <span className="font-semibold text-white">{coin.name}</span>
                        <span className="font-normal text-yellow mb-3">{coin.symbol}</span>
                        <span className="text-xs font-normal text-white/80 mb-2.5">
                            {t('token-created-by')}{' '}
                            <span
                                className="text-white"
                                onClick={() => {
                                    if (coin.user?.username) {
                                        WebApp.openTelegramLink(
                                            'https://t.me/' + coin.user?.username
                                        );
                                    }
                                }}
                            >
                                {coin.user.username
                                    ? `@${coin.user.username}`
                                    : t('anonymous').toLowerCase()}
                            </span>{' '}
                            {dayjs
                                .duration(dayjs(coin.chainCreatedAt).diff(dayjs()))
                                .humanize(true)}
                        </span>
                        <div className="">
                            <span className="font-normal text-xs text-white/80">
                                {t('token.chart.marketCap')}:
                            </span>
                            <TokenMarketCap onlyNumber value={getCoinMarketCap(coin, price)} />
                        </div>
                    </div>
                    <div className="absolute top-4 right-4">
                        <GoldShareCoinButton />
                    </div>
                </div>
            </Card>
            <Socials coin={coin} />
            <Card className="!bg-black !p-2.5">
                <p className="text-sm font-normal text-white mb-3">{coin.description}</p>
                <div
                    className="grid font-normal text-white/50 items-center gap-2.5"
                    style={{
                        gridTemplateColumns: 'auto 1fr',
                    }}
                >
                    <span className="text-xs ">{t('token.address')}</span>
                    <div className="flex items-center gap-2">
                        <span className="text-oxid-green-v2 text-sm font-normal">
                            {squeezeAddress(
                                Address.parse(coin.address).toString({ bounceable: true })
                            )}
                        </span>
                        <div className="flex items-center gap-1">
                            <CopyButton
                                text={Address.parse(coin.address).toString({ bounceable: true })}
                            >
                                <img
                                    src="/copyAddress.svg"
                                    alt="copy"
                                    className="cursor-pointer h-4"
                                />
                            </CopyButton>
                            <span className="text-lg font-normal">/</span>
                            <button
                                onClick={() =>
                                    openExternalLink(
                                        `https://tonviewer.com/${Address.parse(coin.address).toString({ bounceable: true })}`
                                    )
                                }
                            >
                                <img
                                    src="/tonviewerLink.svg"
                                    alt="tonviewer"
                                    className="h-[1.125rem] cursor-pointer"
                                />
                            </button>
                        </div>
                    </div>
                    <span className="text-xs ">{t('token.authorAddress')}</span>
                    <div className="flex items-center gap-2">
                        <span className="text-oxid-green-v2 text-sm font-normal">
                            {squeezeAddress(
                                Address.parse(coin.authorAddress).toString({ bounceable: true })
                            )}
                        </span>
                        <div className="flex items-center gap-1">
                            <CopyButton
                                text={Address.parse(coin.authorAddress).toString({
                                    bounceable: true,
                                })}
                            >
                                <img
                                    src="/copyAddress.svg"
                                    alt="copy"
                                    className="cursor-pointer h-4"
                                />
                            </CopyButton>
                            <span className="text-lg font-normal">/</span>
                            <button
                                onClick={() =>
                                    openExternalLink(
                                        `https://tonviewer.com/${Address.parse(coin.authorAddress).toString({ bounceable: true })}`
                                    )
                                }
                            >
                                <img
                                    src="/tonviewerLink.svg"
                                    alt="tonviewer"
                                    className="h-[1.125rem] cursor-pointer"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
            <svg
                width="375"
                height="8"
                viewBox="0 0 375 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-3 w-full"
            >
                <path
                    d="M1 1L10.5641 7L20.1282 1L29.6923 7L39.2564 1L48.8205 7L58.3846 1L67.9487 7L77.5128 1L87.0769 7L96.641 1L106.205 7L115.769 1L125.333 7L134.897 1L144.462 7L154.026 1L163.59 7L173.154 1L182.718 7L192.282 1L201.846 7L211.41 1L220.974 7L230.538 1L240.103 7L249.667 1L259.231 7L268.795 1L278.359 7L287.923 1L297.487 7L307.051 1L316.615 7L326.179 1L335.744 7L345.308 1L354.872 7L364.436 1L374 7"
                    stroke="#003CB0"
                    stroke-width="1.2"
                />
            </svg>
            <LiquidityProgress coin={coin} />
            <Card className="!bg-heavy-blue !p-0 border-blue border-4">
                <div className="flex flex-col gap-2.5 relative" id="community-notes">
                    <div className="p-3 pb-0 bg-gradient-to-b from-[#1253D1CC] to-transparent">
                        <RadioSlider
                            className="border-none"
                            value={displayedWidget}
                            onChange={setDisplayedWidget}
                            options={[
                                {
                                    value: 'holders',
                                    label: (
                                        <div className="flex items-center gap-1.5">
                                            <span>{t('holders')}</span>
                                            <span className="text-blue-sky font-normal text-xs">
                                                {coin.holderAmount}
                                            </span>
                                        </div>
                                    ),
                                },
                                {
                                    value: 'notes',
                                    label: t('community-notes'),
                                },
                            ]}
                        />
                    </div>
                    <ActiveWidget tokenId={tokenId} count={parseFloat(coin.noteAmount)} />
                </div>
            </Card>
            <div className="h-[60px]" />
            <div className="bg-[#3A3A3A] py-1.5 px-3 fixed bottom-[100px] w-screen left-0 z-20">
                <BigColorButton variant="green" onClick={navigation.tokenTrade(tokenId)}>
                    {t('token.buy').toUpperCase()}
                </BigColorButton>
            </div>
        </>
    );
};

const Socials = ({ coin }: { coin: Coin }) => {
    if (!(coin.tgChannel || coin.twitter || coin.website || coin.tgChat)) {
        return null;
    }

    return (
        <div className="flex items-center w-full justify-between gap-1 -mt-2">
            {coin.tgChannel && <Social type="tgChannel" url={coin.tgChannel ?? ''} />}
            {coin.tgChat && <Social type="tgChat" url={coin.tgChat ?? ''} />}
            {coin.twitter && <Social type="twitter" url={coin.twitter ?? ''} />}
            {coin.website && <Social type="website" url={coin.website ?? ''} />}
        </div>
    );
};

type SocialType = 'tgChannel' | 'tgChat' | 'twitter' | 'website';

const socialIcons: Record<SocialType, string> = {
    tgChannel: '/about_tgChannel.svg',
    tgChat: '/about_tgChat.svg',
    twitter: '/about_twitter.svg',
    website: '/about_website.svg',
};

const socialTitles = {
    tgChannel: 'token.about.tgChannel',
    tgChat: 'token.about.tgChat',
    twitter: 'token.about.twitter',
    website: 'token.about.website',
} as const;

const Social = ({
    type,
    url,
}: {
    type: 'tgChannel' | 'twitter' | 'website' | 'tgChat';
    url: string;
}) => {
    const { t } = useTranslation();

    const onClick = () => {
        if (url.includes('t.me')) {
            WebApp.openTelegramLink(url);
        } else {
            openExternalLink(url);
        }
    };

    return (
        <button
            className="flex flex-1 flex-col gap-1 items-center bg-blue p-2.5 border-4 border-t-white/25 border-l-white/25 border-b-black/25 border-r-black/25 cursor-pointer"
            onClick={onClick}
        >
            <img src={socialIcons[type]} alt="" className="w-6 h-6" />
            <span className="text-white text-xs font-normal">{t(socialTitles[type])}</span>
        </button>
    );
};
