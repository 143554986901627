import { ReactNode } from 'react';

type Props = {
    value: number;
    className?: string;
    icon?: string | ReactNode;
};

export const HoldersAmount = ({ value, className, icon = '/user.svg' }: Props) => {
    const holders = value
        ? Intl.NumberFormat('en', {
              notation: 'compact',
              maximumFractionDigits: 1,
          }).format(Number(value))
        : 'n/a';

    return (
        <div className={`flex items-center ${className}`}>
            {typeof icon === 'string' ? <img src={icon} alt="" className="w-4 h-4" /> : icon}
            <span className="text-xs leading-tight">{holders}</span>
        </div>
    );
};
