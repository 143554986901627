import BigNumber from 'bignumber.js';
import { NumericString } from 'bclSdkOriginal/types.ts';

export function parseValue(amount: string | bigint | number, decimal: number): NumericString {
    const divisor = 10 ** decimal;

    const number = new BigNumber(String(amount));

    const result = number.div(divisor);

    return result.toString() as NumericString;
}
