import { useCoinById } from 'coin/coin.store.ts';
import { useTranslation } from 'i18n';
import { getRandomAvatar } from 'common/utils/getRandomAvatar.ts';
import WebApp from '@twa-dev/sdk';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { Address } from '@ton/core';
import { CopyButton } from 'common/components/copyButton.tsx';
import { TransactionStatus } from 'coin/transactions/transactionStatus.tsx';
import dayjs from 'dayjs';
import { displayNanoValue } from 'coin/utils.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { CoinTransaction } from 'coin/transactions/coinTransactions.store.ts';

export const Transaction = ({ transaction }: { transaction: CoinTransaction }) => {
    const { t } = useTranslation();
    const { coin } = useCoinById();

    return (
        <div className="flex items-center gap-1.5">
            <img
                src={getRandomAvatar(transaction.user?.username ?? 'anonymous')}
                alt="ava"
                className="w-9 h-9 aspect-square"
            />
            <div className="flex flex-col gap-0.5">
                <span
                    className="text-xs text-white"
                    onClick={() => {
                        if (transaction.user?.username) {
                            WebApp.openTelegramLink('https://t.me/' + transaction.user?.username);
                        }
                    }}
                >
                    @{transaction.user?.username ?? t('anonymous').toLowerCase()}
                </span>
                <div className="flex items-center gap-1">
                    <span className="text-xs font-normal text-white-gray">
                        {squeezeAddress(
                            Address.parse(transaction.actorAddress).toString({ bounceable: true })
                        )}
                    </span>
                    <CopyButton
                        text={Address.parse(transaction.actorAddress).toString({
                            bounceable: true,
                        })}
                    >
                        <svg
                            width={10}
                            height={10}
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.25 0V4H0.25V14H10.25V10H14.25V0H4.25ZM13.25 1H5.25V4H10.25V9H13.25V1ZM9.25 13V5H1.25V13H9.25Z"
                                fill="#CDCDCD"
                            />
                        </svg>
                    </CopyButton>
                </div>
                <div className="flex items-center gap-1">
                    <TransactionStatus status={transaction.type} />
                    <span className="text-2xs font-medium text-white-gray">
                        {dayjs.duration(dayjs(transaction.txUtime).diff(dayjs())).humanize(true)}
                    </span>
                </div>
            </div>
            <div className="ml-auto flex flex-col items-end">
                <span className="text-xs text-white">
                    {displayNanoValue(transaction.tonAmount)} TON
                </span>
                <span className="text-2xs text-white-gray">
                    {Intl.NumberFormat('en', {
                        notation: 'compact',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(parseFloat(parseValue(transaction.coinsAmount, 9)))}
                    &nbsp; {coin ? coin?.symbol : ''}
                </span>
            </div>
        </div>
    );
};
