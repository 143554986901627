import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { StatCard } from 'frens/referralsStatsTable.tsx';
import { Skeleton } from 'common/components/skeleton.tsx';
import { useTranslation } from 'i18n';
import { navigation } from 'common/utils/routeUtils.ts';
import {
    fetchCreateMemesStats,
    useCreateMemesReferralsStats,
} from 'frens/tabs/createMemes.store.ts';
import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { Card } from 'common/components/card.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';

export const CreateMemes = () => {
    const { t } = useTranslation();
    const { stats } = useCreateMemesReferralsStats();

    useAutoFetch(fetchCreateMemesStats);
    return (
        <>
            <div className="flex flex-col items-center w-full gap-5 mb-3">
                <img
                    src="/create_memes.png"
                    alt="memes"
                    className="w-[4.5rem] aspect-square mt-3"
                />
                <div className="w-full flex flex-col gap-2 px-5">
                    <TextWithShadow className="italic text-center">
                        {t('frens.memes.title').toUpperCase()}
                    </TextWithShadow>
                    <p
                        className="text-xs text-white text-center"
                        dangerouslySetInnerHTML={{
                            __html: t('frens.memes.description').replace(
                                /<>(.*?)<\/>/gi,
                                '<span class="font-bold text-dark-yellow">$1</span>'
                            ),
                        }}
                    />
                </div>
            </div>
            <div className="flex items-center gap-2 w-full mb-5">
                <BigColorButton
                    variant="yellow"
                    icon={<img src="/arrow_right.gif" alt="open" className="w-6" />}
                    onClick={navigation.createToken}
                >
                    {t('frens.memes.action').toUpperCase()}
                </BigColorButton>
            </div>
            {stats ? (
                <Card className="!bg-light-blue bg-gradient-to-b from-white/40 to-transparent p-4 flex flex-col items-center gap-3 w-full">
                    <TextWithShadow className="italic">
                        {t('frens.stats.title').toUpperCase()}
                    </TextWithShadow>
                    <div className="grid grid-cols-2 items-center gap-3 w-full">
                        <StatCard
                            icon="/gem.gif"
                            value={`${parseFloat(parseValue(stats.earned, 9)) > 1 ? normalizedToFixed(parseValue(stats.earned, 9), 2) : normalizedToFixed(parseValue(stats.earned, 9), 4)} TON`}
                            label={t('frens.memes.earned').toUpperCase()}
                        />
                        <StatCard
                            icon="/coin.gif"
                            value={stats.coinAmount}
                            label={t('frens.memes.coins').toUpperCase()}
                        />
                    </div>
                    <BigColorButton disabled variant="green">
                        {t('frens.claimReward')}
                    </BigColorButton>
                    <p className="text-xs text-white">{`${t('frens.claimRewardCondition')}`}</p>
                </Card>
            ) : (
                <Skeleton size="lg" height="96px" />
            )}
            {/*<DetailedFrensList />*/}
        </>
    );
};
