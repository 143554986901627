import { ColorType, createChart, Range, Time } from 'lightweight-charts';
import { useEffect, useRef } from 'react';

type Candle = {
    time: Time;
    open: number;
    high: number;
    low: number;
    close: number;
};

type ChartComponentsProps = {
    data: Candle[];
    onVisibleRangeChange?: (range: Range<Time>) => void;
    visibleRange?: [number, number];
    type?: 'candlestick' | 'area';
};

function formatSmallNumber(num: number): string {
    if (num > 0.01) {
        return Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            notation: 'compact',
        }).format(num);
    }
    const numStr = num.toExponential(); // Преобразуем число в экспоненциальный формат
    const match = /(\d+\.\d+)e-(\d+)/.exec(numStr); // Извлекаем число и степень

    if (match) {
        const [, significand, exponent] = match;
        const numZeros = parseInt(exponent) - 1; // Вычитаем 1, т.к. первая цифра уже в формате
        const significantDigits = significand.replace('.', ''); // Убираем точку для получения значащих цифр

        if (significantDigits.length > 3) {
            return `$0.0{${numZeros}}${significantDigits.slice(0, 3)}`;
        }

        return `$0.0{${numZeros}}${significantDigits}`;
    }

    return '$' + num.toString(); // Если не в экспоненциальной записи, возвращаем как есть
}

export const ChartComponent = ({
    data,
    onVisibleRangeChange,
    type = 'candlestick',
}: ChartComponentsProps) => {
    const chartContainerRef = useRef<HTMLDivElement | null>(null); // Явное указание типа
    const seriesRef = useRef<any | null>(null);
    const visibleRangeRef = useRef<Range<Time> | null>(null);
    const chartRef = useRef<any | null>(null);

    useEffect(() => {
        const clientWidth = chartContainerRef.current ? chartContainerRef.current.clientWidth : 0;
        const handleResize = () => {
            chart.applyOptions({ width: clientWidth });
        };

        const container = chartContainerRef.current ?? document.createElement('div');

        const chart = createChart(container, {
            layout: {
                background: { type: ColorType.Solid, color: 'black' },
                textColor: 'white',
            },
            width: clientWidth,
            height: 300,
            timeScale: {
                rightOffset: 10,
                barSpacing: 4,
                shiftVisibleRangeOnNewBar: true,
                timeVisible: true,
                tickMarkFormatter: (time: number) => {
                    return new Date(time * 1000).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hourCycle: 'h23',
                    });
                },
            },
            grid: {
                vertLines: {
                    color: 'transparent', // Убирает вертикальные линии
                    visible: false, // Отключает видимость вертикальных линий
                },
                horzLines: {
                    color: 'transparent', // Убирает горизонтальные линии
                    visible: false, // Отключает видимость горизонтальных линий
                },
            },
            rightPriceScale: {
                visible: true,
                autoScale: false,
                scaleMargins: {
                    top: 0.1,
                    bottom: 0.1,
                },
            },
            localization: {
                priceFormatter: (value: number) => formatSmallNumber(value).slice(0, 6),
                timeFormatter: (time: number) => {
                    return new Date(time * 1000).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hourCycle: 'h23',
                    });
                },
            },
        });

        if (type === 'area') {
            seriesRef.current = chart.addAreaSeries({
                topColor: 'rgba(38,53,218,0.56)',
                bottomColor: 'rgba(38,53,218, 0.04)',
                lineColor: 'rgba(38,53,218, 1)',
                lineWidth: 2,
                priceLineVisible: true,
                priceLineWidth: 2,
                priceScaleId: 'right',
            });
        } else {
            seriesRef.current = chart.addCandlestickSeries({
                upColor: '#3674d9',
                downColor: '#e13255',
                borderVisible: false,
                wickUpColor: '#3674d9',
                wickDownColor: '#e13255',
                priceScaleId: 'right',
            });
        }
        chartRef.current = chart;

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);

            chart.remove();
        };
    }, []);

    useEffect(() => {
        const logVisibleRange = () => {
            const visibleRange = chartRef.current.timeScale().getVisibleRange();
            if (visibleRange !== null) {
                if (onVisibleRangeChange) {
                    onVisibleRangeChange({
                        ...visibleRange,
                        to: visibleRange.to > Date.now() / 1000 ? Date.now() : visibleRange.to,
                    });
                }
            }
        };

        const timeScaleSubscriber = () => {
            logVisibleRange();
        };

        chartRef.current.timeScale().subscribeVisibleTimeRangeChange(timeScaleSubscriber);

        return () => {
            chartRef.current.timeScale().unsubscribeVisibleTimeRangeChange(timeScaleSubscriber);
        };
    }, [onVisibleRangeChange, data]);

    useEffect(() => {
        if (seriesRef.current && chartRef.current) {
            visibleRangeRef.current = chartRef.current.timeScale().getVisibleRange();

            if (type === 'area') {
                const dataForArea = data.map((item) => {
                    return {
                        time: item.time,
                        value: item.close,
                    };
                });
                seriesRef.current.setData(dataForArea);
            } else {
                seriesRef.current.setData(data);
            }
        }
    }, [data]);

    return <div ref={chartContainerRef} className="w-full overflow-hidden" />;
};
