import { FC, HTMLProps, ReactNode, useEffect, useState } from 'react';
import { Card } from 'common/components/card.tsx';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import WebApp from '@twa-dev/sdk';

type DrawerProps = {
    open: boolean;
    onClose?: HTMLProps<HTMLDivElement>['onClick'];
    closable?: boolean;
    children: ReactNode | ReactNode[] | null;
    position?: 'left' | 'right' | 'top' | 'bottom';
    isNew?: boolean;
};

const positionClass = {
    left: 'left-0 top-0 max-w-[90%] h-full',
    right: 'right-0 top-0 max-w-[90%] h-full',
    top: 'top-0 left-0 max-h-[90%] w-full',
    bottom: 'bottom-0 left-0 max-h-[90%] w-full',
};

const closePositionClass = {
    left: '-translate-x-full',
    right: 'translate-x-full',
    top: '-translate-y-full',
    bottom: 'translate-y-full',
};

const openPositionClass = {
    left: 'translate-x-0',
    right: 'translate-x-0',
    top: 'translate-y-0',
    bottom: 'translate-y-0',
};

const createContainer = () => {
    return document.querySelector('body')!;
};

export const Drawer: FC<DrawerProps> = ({
    open,
    onClose,
    children,
    position = 'right',
    closable = true,
}) => {
    const [container] = useState(createContainer);
    const [height, setHeight] = useState(WebApp.viewportHeight);

    const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
        if (closable && onClose) {
            if (event.currentTarget === event.target) {
                onClose(event);
            }
        }
    };

    useEffect(() => {
        const handleViewportChanged = () => {
            setHeight(WebApp.viewportHeight);
        };

        WebApp.onEvent('viewportChanged', handleViewportChanged);
        return () => {
            WebApp.offEvent('viewportChanged', handleViewportChanged);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'auto';
        const appContainer = document.querySelector('#app');
        if (open) {
            appContainer?.classList.add('overflow-hidden');
        } else {
            appContainer?.classList.remove('overflow-hidden');
        }
    }, [open]);

    return (
        <>
            {createPortal(
                <div
                    className={classNames('fixed top-0 left-0', {
                        'opacity-0 -z-[100] pointer-events-none h-0 w-0': !open,
                        'opacity-100 z-[100] w-full': open,
                    })}
                    style={{ height: open ? height : 0 }}
                >
                    <div
                        className={classNames(
                            `absolute z-[99] bg-black/60 transition-opacity h-full w-full top-0 left-0`,
                            {
                                'opacity-100': open,
                                'opacity-0 pointer-events-none': !open,
                            }
                        )}
                        onClick={handleClose}
                    />
                    <Card
                        className={classNames(
                            `absolute z-[150] shadow-lg transform transition-transform ${positionClass[position]}
                        ${open ? openPositionClass[position] : closePositionClass[position]}
                         !bg-blue bg-gradient-to-b from-white/40 to-transparent !p-4`,
                            {
                                [`!pb-12 !-mb-10`]: position === 'bottom',
                            }
                        )}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {children}
                    </Card>
                </div>,
                container
            )}
        </>
    );
};
