import { ForwardedRef, forwardRef, HTMLProps } from 'react';

type InputProps = Omit<HTMLProps<HTMLInputElement>, 'onChange'> & {
    onChange?: (value: string) => void;
    className?: string;
};

export const Input = forwardRef(
    (
        { onChange, className, onKeyUp, ...props }: InputProps,
        ref: ForwardedRef<HTMLInputElement>
    ) => {
        const classNames = `flex-1 border border-black bg-dark-blue p-2 text-xs placeholder:text-[#8FADE7] text-white ${className}`;
        if (props.type === 'number') {
            return (
                <input
                    {...props}
                    inputMode="decimal"
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e.currentTarget.value.replace(',', '.'));
                        }
                    }}
                    placeholder="0.00"
                    className={classNames}
                    onKeyUp={(e) => {
                        if (e.which === 13) {
                            e.currentTarget.blur();
                        }
                    }}
                    type="numeric"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    ref={ref}
                />
            );
        }

        return (
            <input
                {...props}
                onChange={(e) => onChange && onChange(e.currentTarget.value)}
                className={classNames}
                onKeyUp={(e) => {
                    if (e.which === 13) {
                        e.currentTarget.blur();
                    }
                }}
                ref={ref}
            />
        );
    }
);
