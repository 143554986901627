import WebApp from '@twa-dev/sdk';
import { useEffect } from 'react';
import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/react';
import { IS_TON_KEEPER } from 'common/constants';
import { useNavigate } from 'react-router';

type BackButtonStore = {
    showDttm: number;
    prevPath: string | null;
    callback?: () => void;
    goBack?: boolean;
};

const $backButton = map<BackButtonStore>({
    showDttm: 0,
    prevPath: null,
});

const getBackButtonShowDttm = () => {
    return $backButton.get().showDttm;
};

const getBackButtonPrevPath = () => {
    return $backButton.get().prevPath;
};

const setBackButtonShowDttm = action($backButton, 'setShowDttm', (store, showDttm: number) => {
    store.setKey('showDttm', showDttm);
});

const setBackButtonPrevPath = action($backButton, 'setPrevPath', (store, prevPath: string) => {
    store.setKey('prevPath', prevPath);
});

const setBackButtonCallback = action(
    $backButton,
    'setBackButtonCallback',
    (store, callback?: () => void) => {
        store.setKey('callback', callback);
    }
);

const setBackButtonGoBack = action($backButton, 'setBackButtonGoBack', (store, value?: boolean) => {
    store.setKey('goBack', value);
});

export const showBackButton = (callback: () => void) => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => {
        callback();
        hideBackButton();
    });
};

export const hideBackButton = (showDttm?: number) => {
    const storedDttm = getBackButtonShowDttm();
    if (!showDttm) {
        WebApp.BackButton.hide();
    }

    if (showDttm && storedDttm === showDttm) {
        WebApp.BackButton.hide();
    }
};

const selectBackButtonStore = computed($backButton, (store) => store);

export const useBackButtonStore = () => useStore(selectBackButtonStore);

type BackButtonProps = {
    callback?: () => void;
    from: string;
    goBack?: boolean;
};

export const useBackButton = ({ callback, goBack, from }: BackButtonProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        const now = Date.now();
        const prevPath = getBackButtonPrevPath();
        if (IS_TON_KEEPER) {
            setBackButtonCallback(callback);
            setBackButtonGoBack(goBack);
            setBackButtonPrevPath(from);
            return;
        }
        if (goBack && !callback && (!prevPath || prevPath === from)) {
            return () => {
                hideBackButton();
                setBackButtonPrevPath(from);
            };
        }
        setBackButtonShowDttm(now);
        if (callback) {
            showBackButton(callback);
        } else if (goBack && prevPath) {
            showBackButton(() => {
                navigate(prevPath);
            });
        }
        return () => {
            hideBackButton(now);
            setBackButtonPrevPath(from);
        };
    }, []);
};

export const notificationFailure = () => {
    WebApp.HapticFeedback.notificationOccurred('error');
};

export const notificationSuccess = () => {
    WebApp.HapticFeedback.notificationOccurred('success');
};

export const notificationWarning = () => {
    WebApp.HapticFeedback.notificationOccurred('warning');
};

export const setBackgroundSecondary = () => {
    WebApp.setBackgroundColor('secondary_bg_color');
};

export const setHeaderSecondary = () => {
    WebApp.setHeaderColor('secondary_bg_color');
};

export const setBackgroundPrimary = () => {
    WebApp.setBackgroundColor('bg_color');
};

export const setHeaderPrimaryColor = () => {
    WebApp.setHeaderColor('#E4E8F0');
};

export const hideMainButton = () => {
    WebApp.MainButton.hide();
};

export const getStartParam = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlStartParam = urlSearchParams.get('start_param');

    return WebApp.initDataUnsafe.start_param || urlStartParam;
};

export const closeWebApp = () => {
    return WebApp.close();
};

export const expandWebApp = () => {
    WebApp.expand();
};
