import { Skeleton } from 'common/components/skeleton.tsx';
import { EmptyList } from 'common/components/emptyList.tsx';
import { Card } from 'common/components/card.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import dayjs from 'dayjs';
import { TokenMarketCap } from 'coin/components/tokenMarketCap.tsx';
import { getCoinMarketCap } from 'coin/utils.ts';
import { TokenCreatedBy } from 'coin/components/tokenCreatedBy.tsx';
import { Progress } from 'common/components/progress.tsx';
import classNames from 'classnames';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import { useTon } from 'main/main.store.ts';
import { HoldersAmount } from 'coin/holders/holdersAmount.tsx';
import { NotesAmount } from 'coin/communityNotes/notesAmount.tsx';
import { PriceChange } from 'coin/components/priceChange.tsx';
import {
    fetchCoinsList,
    pullNewCoinsList,
    SortListType,
    useCoinsList,
} from 'main/coinsList.store.ts';
import { TickerLabel } from 'coin/components/tickerLabel.tsx';
import { AuthorWalletBalance } from 'coin/components/authorWalletBalance.tsx';
import { parseValue } from 'common/utils/parseValue.ts';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { QuickBuyButton } from 'main/quickBuyButton.tsx';
import { CoinStatus } from 'coin/coin.store.ts';
import { useUser } from 'user/user.store.ts';
import { formatNewDuration } from 'main/utils.ts';

const isNew = (ts: string) => {
    return dayjs(ts).unix() * 1000 > Date.now() - 6 * 60 * 60 * 1000;
};

export const CoinsList = () => {
    const { coins, isFetching, canFetchMore, search, searchResults, sortType } = useCoinsList();
    const { quickBuyEnabled } = useUser();

    const anchorRef = useScrollToFetchData(fetchCoinsList);

    useAutoFetch(pullNewCoinsList, 3000);

    const { price } = useTon();

    const coinsToDisplay = search && searchResults.length ? searchResults : coins;

    return (
        <>
            {isFetching && (
                <>
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                </>
            )}
            {!isFetching && !coinsToDisplay.length && <EmptyList />}
            {coinsToDisplay.map((coin) => (
                <Card
                    className="flex flex-col gap-2 !p-3"
                    onClick={navigation.tokenTrade(coin.id)}
                    key={coin.id}
                >
                    <div className="flex items-center gap-2">
                        <div className="relative">
                            <img
                                src={coin.imageUrl}
                                alt={coin.imageName}
                                className="min-w-[72px] w-[72px] max-h-[72px] object-cover object-center"
                            />
                            <span className="absolute left-0 bottom-0 bg-dark-green text-2xs text-white px-0.5 py-[1px]">
                                {isNew(coin.chainCreatedAt) ? `NEW ` : ''}
                                {formatNewDuration(
                                    dayjs
                                        .duration(dayjs(coin.chainCreatedAt).diff(dayjs()))
                                        .humanize()
                                )}
                            </span>
                        </div>
                        <div className="flex flex-col h-full justify-between gap-1 w-[calc(100%-80px)]">
                            <div className="flex items-center gap-1">
                                <span className="text-sm font-semibold truncate">{coin.name}</span>
                                <PriceChange
                                    value={parseFloat(coin.priceChange1H)}
                                    className="text-xs"
                                />
                                <TickerLabel className="ml-auto">{coin.symbol}</TickerLabel>
                            </div>
                            <TokenMarketCap
                                className="!text-[#53A700]"
                                blockClassName="!bg-[#53A700] !text-white"
                                isNew
                                value={getCoinMarketCap(coin, price)}
                            />
                            <div className="flex items-center gap-3">
                                <HoldersAmount value={parseFloat(coin.holderAmount)} />
                                <NotesAmount value={parseFloat(coin.noteAmount)} />
                                {sortType === SortListType.New && (
                                    <AuthorWalletBalance
                                        value={parseValue(coin.authorBalanceInTON, 9)}
                                    />
                                )}
                            </div>
                            <TokenCreatedBy className="!text-black">
                                {coin.user.username}
                            </TokenCreatedBy>
                        </div>
                    </div>
                    <Progress progress={parseFloat(coin.bondingCurveProgressTon)} />
                    {coin.status !== CoinStatus.LiquiditySent && quickBuyEnabled && (
                        <QuickBuyButton coin={coin} />
                    )}
                </Card>
            ))}
            <div
                className={classNames('w-full flex flex-col gap-3', {
                    [`hidden`]: !canFetchMore || search,
                })}
                ref={anchorRef}
            >
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
            </div>
        </>
    );
};
