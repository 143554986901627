import {
    CoinStatsPeriod,
    fetchCoinStats,
    setCoinStatsPeriod,
    useCoinStats,
} from 'coin/coinStats/coinStats.store.ts';
import { Coin } from 'coin/coin.store.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { parseValue } from 'common/utils/parseValue.ts';
import { normalizedToFixed } from 'common/utils/normalizedToFixed.ts';
import { useEffect } from 'react';
import { PriceChange } from 'coin/components/priceChange.tsx';
import { useTon } from 'main/main.store.ts';

type Props = {
    coinId: Coin['id'];
};

export const CoinStats = ({ coinId }: Props) => {
    const { price } = useTon();
    const { stats: allPeriodStats, selectedPeriod, isLoading } = useCoinStats();

    useEffect(() => {
        fetchCoinStats(coinId);
    }, []);

    const stats = allPeriodStats[selectedPeriod];

    return (
        <>
            <div className="flex items-center gap-1 mb-2">
                {Object.values(CoinStatsPeriod).map((value) => (
                    <Period
                        key={value}
                        value={value}
                        onChange={setCoinStatsPeriod}
                        priceChange={allPeriodStats?.[value]?.priceChangePercentage}
                        selected={value === selectedPeriod}
                    />
                ))}
            </div>
            {isLoading ? (
                <Skeleton size="lg" height={142} />
            ) : (
                <div className="grid grid-cols-4 gap-2">
                    <LabelValue label="TXNS" value={stats?.transactionsCount ?? '0'} />
                    <div className="col-span-3">
                        <CounterBar
                            left={{ label: 'BUYS', value: stats?.buyCount ?? '0' }}
                            right={{ label: 'SELLS', value: stats?.sellCount ?? '0' }}
                        />
                    </div>
                    <LabelValue
                        label="VOLUME"
                        value={
                            '$' +
                            normalizedToFixed(
                                parseFloat(parseValue(stats?.volume ?? '0', 9)) * price,
                                2
                            )
                        }
                    />
                    <div className="col-span-3">
                        <CounterBar
                            dollars
                            left={{
                                label: 'BUY VOL',
                                value: normalizedToFixed(
                                    parseFloat(parseValue(stats?.buyVolume ?? '0', 9)) * price,
                                    2
                                ),
                            }}
                            right={{
                                label: 'SELL VOL',
                                value: normalizedToFixed(
                                    parseFloat(parseValue(stats?.sellVolume ?? '0', 9)) * price,
                                    2
                                ),
                            }}
                        />
                    </div>
                    <LabelValue label="TRADERS" value={stats?.tradersCount ?? '0'} />
                    <div className="col-span-3">
                        <CounterBar
                            left={{ label: 'BUYERS', value: stats?.buyerCount ?? '0' }}
                            right={{ label: 'SELLERS', value: stats?.sellerCount ?? '0' }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

type PeriodProps = {
    value: CoinStatsPeriod;
    onChange: (value: CoinStatsPeriod) => void;
    priceChange?: string;
    selected: boolean;
};
const Period = ({ value, onChange, priceChange, selected }: PeriodProps) => {
    return (
        <button
            className={`border ${selected ? 'bg-light-blue bg-gradient-to-b from-transparent via-white/40 to-transparent' : 'bg-dark-blue'} flex-1 h-12 flex flex-col items-center justify-center`}
            onClick={() => onChange(value)}
        >
            <span className="text-white text-xs">{value}</span>
            <PriceChange
                value={parseFloat(priceChange ?? '0')}
                colors={{
                    positive: 'text-oxid-green',
                    negative: 'text-[#FF2E58]',
                }}
            />
        </button>
    );
};

type LabelValueProps = {
    label: string;
    value?: string;
    align?: 'start' | 'end';
};

const LabelValue = ({ label, value = '0', align = 'start' }: LabelValueProps) => {
    return (
        <div className={`flex flex-col items-${align}`}>
            <span className="text-blue-sky text-xs">{label}</span>
            <span className="text-white text-xs">{value}</span>
        </div>
    );
};

type CounterBarProps = {
    left: LabelValueProps;
    right: LabelValueProps;
    dollars?: boolean;
};

const CounterBar = ({ left, right, dollars }: CounterBarProps) => {
    const leftValue = parseFloat(left.value ?? '0');
    const rightValue = parseFloat(right.value ?? '0');
    const total = leftValue + rightValue;
    return (
        <div className="flex flex-col gap-1">
            <div className="flex justify-between">
                <LabelValue label={left.label} value={`${dollars ? '$' : ''}${left.value}`} />
                <LabelValue
                    label={right.label}
                    value={`${dollars ? '$' : ''}${right.value}`}
                    align="end"
                />
            </div>
            <div className="w-full h-1.5 flex items-stretch">
                <div
                    className="bg-gradient-to-r from-[#3A8200] via-[#91CB00] to-[#3A8200] h-1.5 relative"
                    style={{
                        width: `${(leftValue / total) * 100}%`,
                    }}
                >
                    <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-transparent via-white/30 to-transparent" />
                </div>
                <div
                    className="bg-gradient-to-r from-[#E0002D] via-[#FF2E58] to-[#E0002D] h-1.5 relative"
                    style={{
                        width: `${(rightValue / total) * 100}%`,
                    }}
                >
                    <div className="absolute left-0 top-0 w-full h-full bg-gradient-to-b from-transparent via-white/30 to-transparent" />
                </div>
            </div>
        </div>
    );
};
