import { isDictionaryKey, useTranslation } from 'i18n';
import { FC, useMemo } from 'react';

type Props = {
    meta: {
        error: string;
        touched: boolean;
    };
};

export const InputError: FC<Props> = ({ meta }) => {
    const { t } = useTranslation();

    const errorText = useMemo<string>(() => {
        if (isDictionaryKey(meta.error)) {
            return t(meta.error);
        }
        return meta.error;
    }, [meta.error]);

    return (
        meta.error &&
        meta.touched && (
            <span className={'text-red-error font-bold text-sm mb-1.5'}>{errorText}</span>
        )
    );
};
