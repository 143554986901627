import { HTMLProps, useState } from 'react';
import { Drawer } from 'common/components/drawer.tsx';
import { BuyTokenWidget } from 'coin/components/buyTokenWidget.tsx';
import { Coin } from 'coin/coin.store.ts';
import { gtag } from 'gtag.ts';

type QuickBuyButtonProps = {
    coin: Coin;
};

export const QuickBuyButton = ({ coin }: QuickBuyButtonProps) => {
    const [isBuyDrawerOpen, setIsBuyDrawerOpen] = useState(false);
    const [delayedIsBuyDrawerOpen, setDelayedIsBuyDrawerOpen] = useState(false);

    const onQuickBuyClick: HTMLProps<HTMLButtonElement>['onClick'] = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsBuyDrawerOpen(true);
        setTimeout(() => {
            setDelayedIsBuyDrawerOpen(true);
        }, 100);
        gtag('quick_buy_opened', {});
    };

    const onDrawerClose: HTMLProps<HTMLDivElement>['onClick'] = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDelayedIsBuyDrawerOpen(false);
        setTimeout(() => {
            setIsBuyDrawerOpen(false);
        }, 300);
    };
    return (
        <>
            <button className="relative" onClick={onQuickBuyClick}>
                <div className="absolute h-full shadow-[0px_4px_4px_0px_#FFFFFF40_inset] w-full" />
                <div className="bg-gradient-to-r from-[#EF7E0D] via-[#E9C214] to-[#EF7E0D] rounded-xl flex items-center justify-center gap-2 p-2.5 text-xs shadow-[0px_-4px_4px_0px_#00000040_inset]">
                    <img src="/gem.gif" alt="" className="w-3 h-3" />
                    2.99 TON
                </div>
            </button>
            {isBuyDrawerOpen && (
                <Drawer open={delayedIsBuyDrawerOpen} onClose={onDrawerClose} position="bottom">
                    <BuyTokenWidget coin={coin} defaultAmount="2.99" />
                </Drawer>
            )}
        </>
    );
};
